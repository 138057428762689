import * as React from "react";
import LoginForm from "./AdminLogin";
import Alogo from '../../assets/images/logo.png'

const WelcomeSection: React.FC = () => {
  return (
    <section className="flex flex-col items-center w-full md:w-3/4 lg:w-1/2 p-4">
      <img
        loading="lazy"
        src={Alogo}
    
        alt="ABRAMS logo"
        className="object-contain w-28   max-w-full"
      />
      <div className="flex flex-col items-center text-center">
        <div className="text-black mb-4 md:mt-4">
          <span className="font-bold  md:text-[40px] lg:text-[40px] ">
            Welcome to <span className="text-red-600">ABRAMS</span>
          </span>
          {/* <span className="  text-left  md:text-sm mt-2 block max-w-md mx-auto ">
            Access your dashboard to streamline tasks and keep your bookstore
            running smoothly. Your dedication to excellence makes all the
            difference.
          </span> */}
        </div>
        <LoginForm />
      </div>
    </section>
  );
};

export default WelcomeSection;



