import React, { useEffect, useRef } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { emailPattern } from "../../../constants/validationPatterns";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { emptyforgotPassObj, mailSentOnce, sendForgotMail } from "../../../redux/reducers/forgot-passwordReducer";
import { createIntervalTime, minusPerSecondSelectedTime, selectedTime, time, updateTime } from "../../../redux/reducers/timelineReducer";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Alogo from '../../../assets/images/logo.png'

interface FormValues {
  email: string;
}

const ForgotPasswordForm: React.FC = () => {

    const intervalRef = useRef<NodeJS.Timeout | null>(null); 
    const dispatch = useDispatch<AppDispatch>()
    const timeNow = useSelector(time)
    const createdTime = useSelector(selectedTime)
    const mailSend = useSelector(mailSentOnce)
    const { control, handleSubmit, formState: { errors } , watch, setError} = useForm<FormValues>();

    useEffect(() => {
      intervalRef.current = setInterval(() => {
        dispatch(minusPerSecondSelectedTime())

          dispatch(updateTime(createdTime));

          if (createdTime && createdTime <= 0) {
              if (intervalRef.current) {
                  clearInterval(intervalRef.current);
                  dispatch(emptyforgotPassObj())
              }
          }
      }, 1000);

      return () => {
          if (intervalRef.current) {
              clearInterval(intervalRef.current);
          }
      };
    }, [createdTime]); 

    useEffect(() => {
      if(mailSend?.status === 200){
        toast.dismiss()
        toast.success(mailSend.message)
        dispatch(emptyforgotPassObj())
      }
    },[mailSend])

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    dispatch(sendForgotMail({email:data.email}))
    dispatch(createIntervalTime(30))
  };
console.log(mailSend?.mailOnceSent)
  return (
    <section className="flex flex-col items-center w-full md:w-3/4 lg:w-1/2 p-4  ">
       <img
        loading="lazy"
        src={Alogo}
    
        alt="ABRAMS logo"
        className="object-contain w-28   max-w-full"
      />
     <div className="flex flex-col items-center text-center">
     <div className="text-black mb-6  md:mt-4">
     <span className="font-bold  md:text-[40px] lg:text-[40px] ">
          Forgot Password?
        </span>
        <p className="text-sm md:text-sm lg:text-sm text-left mx-4 ">
          Enter your email, and we'll send you a link to reset your
          password.
        </p>
      </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}  className="flex flex-col w-full max-w-md mx-auto md:max-w-lg ">
        <div className="flex flex-col text-base rounded-md text-zinc-400 mx-4">
          <label htmlFor="email" className="sr-only">
            Email address
          </label>
          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: emailPattern
            }}
            render={({ field }) => (
              
              <input
                type="email"
                id="email"
                placeholder="Email address"
                className={`px-6 py-3 rounded-md font-poppins text-[15px] bg-zinc-100 max-md:px-5 max-md:max-w-full ${
                  errors.email ? 'border-red-600' : ''
                }`}
                {...field}
              />
            )}
          /> {errors.email && <p className="text-red-600 mb-2 text-sm text-left m-0">{errors.email.message}</p>}
           <button
          type="submit"
          className="flex items-center justify-center 
        font-bold  text-[17px] text-white rounded-md w-full
         max-w-lg cursor-pointer bg-red-600 border border-red-600 py-3 px-4 mt-4 transition-colors duration-300 hover:bg-red-700"
          disabled={(createdTime ?? 0) > 0 }
        >
          Submit
        </button>
       
         
        </div>
        {timeNow === 0 && watch("email") && watch("email")?.length && !errors.email?.message && 
        (<p className="text-start text-gray-500 text-sm mx-4">
          Email not Received?{" "}
          <button 
            type="button" 
            className="font-poppins text-indigo-600 cursor-pointer" 
            onClick={() => {
              if(!watch("email")?.length)
                return setError("email",{message:"Email is required"})
              else if(!errors.email?.message) {
                dispatch(sendForgotMail({email:watch("email")}))
                dispatch(createIntervalTime(30))
              }
            }}
            disabled={(createdTime ?? 0) > 0 }
          >
            Resend
          </button>
          
        </p>
        )}
        {timeNow !== 0 && 
        <span className="text-red-500">resend again in {timeNow} seconds.</span>
        } 
        <Link
        to="/login"
        className="text-sm
         text-blue-500 text-right hover:text-blue-700 transition-colors duration-300  cursor-pointer mx-4 "
      >
    Back to Login
      </Link>
        {/* <button
          type="submit"
          className="flex cursor-pointer items-center justify-center font-bold text-sm md:text-base text-white rounded-md w-full bg-red-600 border border-red-600 px-4 py-2"
          // disabled={(createdTime ?? 0) > 0 }
        >
          Submit
        </button> */}
      </form>
    </section>
  );
};

export default ForgotPasswordForm;
