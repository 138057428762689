import React, { useState } from "react";
import { FaEye, FaEyeSlash  } from "react-icons/fa";

interface PasswordInputProps {
  id: string;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (data: any) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  defaultValue?: string;
  value?: string;
  error?: any;
}

const PasswordInput: React.FC<PasswordInputProps> = ({ 
  id, 
  name,
  onChange,
  onClick,
  onBlur,
  value,
  defaultValue,
  error
 }) => {
  const [eyeState,setEyeState] = useState(false)
  return (
    <div className="flex relative flex-col mt-5 max-w-full rounded-lg w-[466px]">
      <label htmlFor={id} className="sr-only cursor-pointer">
        Password
      </label>
      <input
        type={eyeState ? "text" : "password"}
        id={id}
        className="px-6 py-3 font-poppins rounded-lg text-[15px] bg-zinc-100 max-md:px-5 max-md:max-w-full"
        placeholder="Password"
        aria-label="Password"
        onClick={onClick}
        defaultValue={defaultValue}
        value={value} 
        onChange={onChange} 
        onBlur={onBlur} 
        name={name}
      />
      {value?.length ? 
      <div className="absolute flex top-3 right-3"  onClick={() => setEyeState(!eyeState)}> 
      {eyeState ? 
            (<div className="text-gray-800 dark:text-white border rounded
              ">
               <span className="cursor-pointer px-2  " ><FaEye/> </span>
             </div>)
            : 
            (<div className="text-gray-800 dark:text-white border rounded
              ">
               <span className="cursor-pointer px-2  " ><FaEyeSlash/> </span>
             </div>   )   
      }
      </div>
      :""
      } 
      {error && (
        <span className="text-red-500 mt-2 text-left absolute bottom-[-20px] left-1">
          {error.message}
        </span>
      )}
    </div>
  );
};

export default PasswordInput;
