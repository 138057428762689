import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios from "axios";
import { API } from "../../constants/API";

interface notificationObj {
    _id:string,
    notification_type:string,
    notification_message:string,
    isread_status:boolean,
    is_deleted:boolean,
    read_at:Date
}
interface notificationState {
  message?: string;
  err?: string;
  status?: string;
  statusCode?: number;
  notifications:notificationObj[]
}


const initialState: notificationState = {
  err: "",
  status: "",
  message: "",
  statusCode: 0,
  notifications:[]
};

export const fetchNotifications = createAsyncThunk(
    "notifications/fetchNotifications",
    async (id:string,{rejectWithValue}) => {
        try{
            const response = await axios.get(`${API.FETCHLOGINUSERNOTIFICATION}`);
            return response;
        }catch(err : any){
            return rejectWithValue(err.response)
        }
})

export const updateNotificationStatus = createAsyncThunk(
  "notifications/updateNotificationStatus",
  async (id:string,{rejectWithValue}) => {
      try{
          const response = await axios.get(`${API.FETCHLOGINUSERNOTIFICATION}`);
          return response;
      }catch(err : any){
          return rejectWithValue(err.response)
      }
})



export const userSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    // login user cases

    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchNotifications.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "succeeded";
        if (action.payload.status == 200) state.notifications = action.payload.data;
        if(action.payload.data.message) state.message = action.payload.data.message;
        state.statusCode = action.payload.status;
      })
      .addCase(fetchNotifications.rejected, (state, action: PayloadAction<any>) => {
        state.status = "failed";
        state.statusCode = action.payload.status;
        if(action.payload.data.message) state.message = action.payload.data.message;
      });
  },
});

export const {  } = userSlice.actions;


export const userProfile = (state: RootState) => state.profileReducer;

export default userSlice.reducer;
