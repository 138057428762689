import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  FormControl,
  FormHelperText,
  MenuItem,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addUser, fetchUsers, Users, emptyStatusMessage, sendOnboardingMail } from "../../redux/reducers/UserReducer";
import { fetchRole, roles} from "../../redux/reducers/roleReducer";
import NewReq from "../../common-components/New request/newReq";
import { CommonDataTable } from "../../common-components/common-material-react-table/CommonMaterialTable";
import { Userheader } from "../../constants/tableConstants/dashboardTableHeaders";
import { AppDispatch, RootState } from "../../redux/store";

import Select from 'react-select'
import { toast } from "react-toastify";
import { IoIosSend } from "react-icons/io";
import { MdDelete, MdEdit } from "react-icons/md";

interface UserDetailsDialogProps {
  open: () => void;
  openDialog: boolean;
  onClose: () => void;
  onSave: (data: {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
  }) => void;
}

interface formRoleObj {
  value:string;
  label:string
}

const UserManage: React.FC<UserDetailsDialogProps> = ({
  open,
  onClose,
  openDialog,
}) => {
  const { control, handleSubmit, reset,formState: { errors }, } = useForm<{
    firstName: string;
    lastName: string;
    email: string;
    role: formRoleObj[];
  }>({
    defaultValues: { firstName: "", lastName: "", email: "", role: [] },
  });

  const userRoles = useSelector(roles);
  const Userdata = useSelector(Users)
  const dispatch = useDispatch<AppDispatch>();
  const addUserStatus = useSelector((state:RootState) => state.UserReducer.statusCode)
  const addUserMessage = useSelector((state:RootState) => state.UserReducer.message)

  const onSubmit = (data: {
    firstName: string;
    lastName: string;
    email: string;
    role: formRoleObj[];
  }) => {
    dispatch(addUser(data));
    handleClose();
  };
  useEffect(() => {
    dispatch(fetchUsers({role:'all'}))
    dispatch(fetchRole());
  }, []);

  useEffect(() =>{
    if(addUserStatus === 409){
      toast.dismiss()
      toast.error(addUserMessage);
      dispatch(emptyStatusMessage())
    }else if(addUserStatus === 200){
      toast.dismiss()
      toast.success(addUserMessage);
      dispatch(emptyStatusMessage())
    }
  },[addUserStatus])
const handleClose=()=>{
  reset();
    onClose();
}

  return (
    <div className="font-poppins overflow-hidden lg:w-full md:w-[94%]">
      <section>
        <NewReq
          title={"User Management"}
          element={
            <div>
              <div className="flex flex-col  rounded-none ">
                <button
                  className="flex font-poppins p-2 bg-red-600 rounded-lg text-[13px] font-semibold text-white cursor-pointer"
                  onClick={open}
                >
                  {/* <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/fe8b03128618f5c25dd1b87eeae0a6296b0f7bb543226c52921b1d68d96add88?placeholderIfAbsent=true&apiKey=a88c36c4e69e48eb98243259c870fe00"
                    alt="New Request Icon"
                    className="object-contain   aspect-square"
                  /> */}
                  + Add Users
                </button>
              </div>
            </div>
          }
        />
        <div className="overflow-x-auto">
          <div className="">
            <CommonDataTable
              tableHeaders={Userheader}
              data={Userdata}
              callback={() => {}}
              changedDataCellColumn={(header, accessor) => {
                if (accessor === "role") {
                  return {
                    accessorKey: accessor,
                    header: header,
                    Cell: ({ row }) => (
                     <div>
                       {
                       row.original.role.map((role : string) => (
                         <span className="m-1 rounded-[6px] capitalize p-[6px] text-[13px] text-[#856404] font-[500] bg-[#fff3cd] ">{role.charAt(0).toUpperCase() + role.slice(1)}</span>
                       ))
                      }
                      </div>
                    ),
                  };
                }else if(accessor === "isActive"){
                  return {
                    accessorKey:accessor,
                    header:header,
                    Cell:({row}) => (
                      <div>
                        <span className={`m-1 rounded-[6px] capitalize p-[6px] text-[13px] ${row.original.isActive !== true ? "text-[#856404]" : "text-[#155724]"} font-[500] ${row.original.isActive !== true ? "bg-[#fff3cd]" : "bg-[#d4edda]"}`}>{row.original.isActive !== true ? "Pending" : "Active"}</span>
                      </div>
                    )
                    // #155724
                  }
                }
                return {
                  accessorKey: accessor,
                  header: header,
                };
              }}
              changeSelectedColumnDataDesign={["role","isActive", "status"]}
              // muiTableRowProps="custom-row"
              colSizes={[100, 100, 100]}
              actionButtons
              customActionButton={(row : any) => (
                <div className="flex justify-center items-center gap-3"> 
                <div className="text-blue-800 dark:text-white border rounded
                 ">
                  <span title="Resend Invite" className="cursor-pointer"  onClick={() => dispatch(sendOnboardingMail(row.original._id))} ><IoIosSend /> </span>
                </div>
                <div className="text-yellow-500 dark:text-white border rounded
                 ">
                  <span title="Update User" className="cursor-pointer" ><MdEdit /></span>
                </div>
                <div className="text-red-500 dark:text-white border rounded
                 ">
                  <span title="Delete User" className="cursor-pointer" ><MdDelete /></span>
                </div>
              </div>  
              )}
            />
          </div>
        </div>
      </section>
      
      <Dialog open={openDialog}  fullWidth maxWidth="sm">
      <DialogTitle className="flex flex-col bg-rose-200 text-center font-poppins ">
        <span className="text-md  font-poppins">Add New User</span> 
        <span className="text-sm  font-poppins">Fill User Details Here</span>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4 p-8   font-poppins">
          <Controller
            name="firstName"
            control={control}
            rules={{ required: 'First name is required' }}
            render={({ field, fieldState }) => (
              <div className="relative flex flex-col">
               <input
                                type="text"
                                placeholder="First Name"
                                className="p-2 border border-solid border-neutral-300  font-poppins rounded-md "
                                {...field}
                              />  {errors?.firstName && <span className="text-red-500 
                               text-xs flex flex-wrap   ">{errors.firstName.message}</span>}
              </div>
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{ required: 'Last name is required' }}
            render={({ field, fieldState }) => (
              <div className="relative flex flex-col">

<input
                                type="text"
                                placeholder="Last Name"
                                className="p-2 border border-solid border-neutral-300  font-poppins rounded-md "
                                {...field}
                              /> 
                {errors?.lastName && <span className="text-red-500 text-xs flex flex-wrap mt-1  ">{errors.lastName.message}</span>}
              </div>
            )}
          />
          <Controller
            name="email"
            control={control}
            rules={{ required: 'Email is required' }}
            render={({ field, fieldState }) => (
              <div className="relative flex flex-col">
               <input
                                type="email"
                                placeholder="Email"
                                className="p-2 border border-solid border-neutral-300  font-poppins rounded-md "
                                {...field}
                              /> 
           {errors?.email && <span className="text-red-500 text-xs flex flex-wrap mt-1   ">{errors.email.message}</span>}
              </div>
            )}
          />
          <Controller
        name="role"
        control={control}
        rules={{ required: 'Role is required' }}
        render={({ field: { onChange, onBlur, value, name, ref }, fieldState }) => (
          // <div className="relative flex flex-col">
          //     <Select 
          //       ref={ref}
          //       name={name}
          //       onBlur={onBlur} 

          //       onChange={(selectedOptions) => onChange(selectedOptions)}
          //       options={userRoles?.map(role => ({
          //         value: role._id,
          //         label: role.roleName
          //       }))} 
          //       isMulti
          //       />
          //        {errors?.role && <span className="text-red-500 text-sm flex flex-wrap absolute bottom-[-18px]  ">{errors.role.message}</span>}
          //    </div>
          <div className="relative flex flex-col  w-full whitespace-nowrap text-sm rounded-lg ">
         
          <Select 
              ref={ref}
              placeholder={"Select "}
              name={name} 
              onBlur={onBlur}
              
              onChange={(selectedOptions) => onChange(selectedOptions)}
                    options={userRoles?.map(role => ({
                      value: role._id,
                      label: role.roleName
                    }))} 
                    isMulti
            />
              {errors?.role && <span className="text-red-500 text-xs flex flex-wrap mt-1">{errors.role.message}</span>}
        </div>
            )}
            />
          <div className="flex justify-end gap-6">
            <Button className="cursor-pointer" onClick={handleClose} variant="contained" color="error">
              Cancel
            </Button>
            <Button type="submit" className="cursor-pointer" variant="contained" color="success">
              Save
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
    </div>
//     font-weight: 500;
//     text-transform: capitalize;
//     padding: 6px;
//     font-size: 13px;
// }
  );
};

export default UserManage;
