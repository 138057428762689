import AccountInfoTable from './AccountInfoTable';
import MainContent from './MainContent';

const Dashboard = () => {
 
  return (
    <div className='overflow-hidden mx-9'>
          <MainContent  />
          <AccountInfoTable />
    </div>
   
    
  );
};

export default Dashboard;