import React from 'react';

interface InputFieldProps {
  label: string;
  value: string[]|string|number|number[];
  isLarge?: boolean;
  className?:string;
}

const InputField: React.FC<InputFieldProps> = ({ label, value, isLarge = false,className }) => {
  return (
    <div className="flex flex-col flex-1 shrink w-full basis-0 min-w-[240px] max-md:max-w-full overflow-hidden ">
      <label htmlFor={label.toLowerCase().replace(/\s/g, '-')} className="text-[14px] self-start font-semibold  leading-none text-neutral-800">
        {label}
      </label>
      {/* <div   className={`flex relative flex-col mt-2.5 font-medium text-neutral-500 ${className} `}> */}
      <div className={`flex relative flex-col justify-center pl-3 mt-2.5  text-neutral-500 z-0 w-full rounded-md border border-solid  bg-zinc-100  border-neutral-200 min-h-[52px] `} >
        <div className={` z-0 h-4 ${className}  ${isLarge ? 'text-2xl font-bold text-zinc-950' : ''} text-[14px]`}>
          {value}
        </div>
      </div>
    </div>
  );
};

export default InputField;