import * as React from "react";
import WelcomeSection from "./WelcomeSection";
import Hero from "../../common-components/HeroSection";

const MainLayout: React.FC = () => {
  return (
    <main className="">
      <section className="flex justify-center items-center">
          <WelcomeSection />
          <Hero/>
      </section>
    </main>
  );
};

export default MainLayout;
