
import { useEffect, useState } from "react";
import { CommonDataTable } from "../../common-components/common-material-react-table/CommonMaterialTable";
import { budgetRequestsTabs } from "./tabSwitchConstants";
import { budgetRequestsHeaders } from "../../constants/tableConstants/tableHeaders";
import { useNavigate } from "react-router-dom";
import MyComponent from "../Approved/MyComponent";
import { useDispatch, useSelector } from "react-redux";
import NewReq from "../../common-components/New request/newReq";
import { user } from "../../redux/reducers/UserReducer";
import { allBudgets, fetchAllBudgetReqs, fetchSelectedBudgetReq, selectedBudgetRow, selectIsbnDataRow, UpdatedBudgetStatus } from "../../redux/reducers/budgetreqReducer";
import { AppDispatch, RootState } from "../../redux/store";
import BudgetIsbnModal from "./budgetIsbnModal";
import moment from "moment";
import { emptyBudgetModal } from "../../redux/reducers/ApprovedDeclined";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { MdCancel } from "react-icons/md";
import { formatCurrency } from "../../common-components/commonFunctions";

interface selectedTabObject {
  tabName?: string;
  tabValue?: string;
  statusCode?: number;
}

interface tableDataInterface {
  isbn: string[];
  title: string;
  deadline: string;
  status: string;
  amount: string;
  statusCode: number;
}

interface rowObj {
  original: tableDataInterface;
}

const BudgetRequest = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userData = useSelector(user);  // login user data
  const budgets = useSelector(allBudgets);  // all budget requests

  const [selectedTab, setSelectedTab] = useState<selectedTabObject>({
    tabName: "Recent Requests",
    tabValue: "recent-requests",
    statusCode: 100,
  });

  const [tab,setTab] = useState("all");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [titleModal, setTitleModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const limit = useSelector((state:RootState) => state.budgetReducer.budgetRequests?.limit)
  const totalPages = useSelector((state:RootState) => state.budgetReducer.budgetRequests?.totalPages)
  const totalDocuments = useSelector((state:RootState) => state.budgetReducer.budgetRequests?.totalDocuments)


  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(fetchAllBudgetReqs({ status: tab, limit: limit || 10, searchTerm:searchTerm, currentPage:1}));
  }, []);

  const budgetStatus = useSelector((state:RootState) => state.ApprovedDeclined.statusCode);
  const budgetMessage = useSelector((state:RootState) => state.ApprovedDeclined.message);
  const budgetUserStatus = useSelector((state:RootState) => state.ApprovedDeclined.budgetUserStatus)


  useEffect(() => {
    if(budgetStatus === 400) {
      toast.error(budgetMessage)
      dispatch(emptyBudgetModal())
    }else if(budgetStatus === 200){
      toast.success(budgetMessage)
      dispatch(UpdatedBudgetStatus(budgetUserStatus))
      dispatch(emptyBudgetModal())
    }else if(budgetStatus === 404){
        toast.error(budgetMessage)
        dispatch(emptyBudgetModal())
    }
  },[budgetStatus, budgetMessage])

  const handleRowClick = (row: any) => {
    dispatch(fetchSelectedBudgetReq({ _id: row._id, approverId: row.approved_by }));
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const showTitleModal = (row: any) => {
    dispatch(selectIsbnDataRow(row));
    setTitleModal(true);
  }

  const handleStatusTabClick = (tab: any) => {
    setTab(tab.statusCode === 100 ? "all" : tab.statusCode);
    dispatch(fetchAllBudgetReqs({ status: tab.statusCode === 100 ? "all" : tab.statusCode, limit: limit || 10, searchTerm:searchTerm, currentPage:1 }));
    setSelectedTab({
      tabName: tab.tabName,
      statusCode: tab.statusCode,
    });
  };

  const handleNextCall=(page:number)=>{
    dispatch(fetchAllBudgetReqs({ status: tab, limit: limit || 10, searchTerm:searchTerm, currentPage:page }));
  }

  const handleSearch = () => {
      const trimmedSearchTerm = searchTerm.trim();
      setSearchTerm(trimmedSearchTerm)
    dispatch(fetchAllBudgetReqs({ status: tab, limit: limit || 10, searchTerm:searchTerm.trim(), currentPage:1}));
  }

  return (
    < div className="overflow-hidden" >
        <div className="flex bg-white w-full pl-8 gap-6">
          {budgetRequestsTabs.map((tab) => (
            <div
              onClick={() => handleStatusTabClick(tab)}
              className="flex  cursor-pointer text-sm flex-col font-semibold py-1 justify-start"
            >
              <p
                className={` shadow-md py-[5px] shadow-gray-300  px-[5px] ${
                  selectedTab.statusCode === tab.statusCode &&
                  "border-b-[3px] border-solid border-red-600"
                }`}
              >
                {tab.tabName}
              </p>
            </div>
          ))}
        </div>
        {/* <div className="container"> */}
        {/* <div className="text-zinc-950 bg-black "> */}
          <section className="flex flex-col text-sm text-zinc-950 mx-8 ">
          <NewReq
            className="mt-4"
            // title={`${selectedTab.tabName} Listing`}
            element={
              <div>
                {userData?.role?.includes("requestor") ? (
                  <div
                    className=" self-stretch  rounded-none"
                  >
                    {/* <div className="mb-4 flex items-center justify-end">
                      <input
                        type="text"
                        {...register("searchString")}
                        placeholder="Search Account Number, ISBN, Title"
                        className="border border-solid border-neutral-200 font-poppins rounded-lg w-[30%] mr-2 focus:border-blue-500 focus:outline-none p-2  "
                      />
                      <button
                        className="bg-red-600 text-white px-4 py-[11px] rounded-md cursor-pointer "
                        onClick={filterbudgetRequests}  
                      >
                        Search
                      </button>
                    </div> */}
                    <button onClick={() => navigate("/newBudgetReq")} className="flex font-poppins p-2 bg-red-600 rounded-lg text-[13px] font-semibold text-white cursor-pointer">
                      <span>+ New Request</span>
                    </button>
                  </div>
                ) : null}
              </div>
            }
          />
            <div className="overflow-x-auto scroll-container mt-4 ">
              <CommonDataTable
                enableTopToolbar={true}
                changeSelectedColumnDataDesign={["isbn", "title", "status", "deadline", "required_amount_budget"]}
                changedDataCellColumn={(header, accessor) => {
                  if (accessor === "status") {
                    return {
                      accessorKey: accessor,
                      header: header,
                      Cell: ({ row }: { row: any }) => (
                        <div
                          className="flex items-center w-full cursor-pointer"
                          onClick={() => handleRowClick(row.original)}
                        >
                          <p
                            className={`${row.original.status === 0 ? "text-yellow-500" : row.original.status === 1 ? "text-green-500" : "text-red-500"} text-center w-full`}
                          >
                            {row.original.status === 0 ? "Waiting for Approval" : row.original.status === 1 ? "Approved" : "Declined"}
                          </p>
                        </div>
                      ),
                    };
                  } else if (accessor === "isbn") {
                    return {
                      accessorKey: accessor,
                      header: header,
                      Cell: ({ row }: { row: any }) => {
                        const isSingleIsbn = row.original.isbn.length === 1;
                        return (
                          <div className={`block ${isSingleIsbn ? "h-auto" : "h-[65px] overflow-y-auto"} items-center`}>
                            {row.original.isbn.map((isbn: string, index: number) => (
                              <p key={isbn} onClick={() => showTitleModal(row.original)}>
                                {isbn}
                              </p>
                            ))}
                          </div>
                        );
                      },
                    };
                  } else if (accessor === "title") {
                    return {
                      accessorKey: accessor,
                      header: header,
                      Cell: ({ row }: { row: any }) => (
                        <div className="flex h-[40px] overflow-y-auto justify-center items-center">
                          <button onClick={() => showTitleModal(row.original)} className="cursor-pointer p-2 rounded-md font-poppins">Show Titles</button>
                        </div>
                      ),
                    };
                  }
                  else if (accessor === "deadline") {
                    return {
                      accessorKey: accessor,
                      header: header,
                      Cell: ({ row }) => {
                        const deadline = moment(row.original.deadline).format("DD/MM/YYYY");
                        return <div>{deadline}</div>;
                      },
                    };
                  } else if (accessor === "required_amount_budget") {
                    return {
                      accessorKey: accessor,
                      header: header,
                      Cell: ({ row }: { row: any }) => (
                        <div>{row.original.required_amount_budget ? formatCurrency(row.original.required_amount_budget) : 0}</div>
                      ),
                    };
                  }
                  return {
                    accessorKey: accessor,
                    header: header,
                  };
                }}
                pageSize={10}
                showRowNumbers
                totalDocuments={totalDocuments}
                totalPages={totalPages}
                topToolbar={({ table }) => (
                  <div className="flex text-sm font-bold py-3 px-6 justify-end">
                    <div className="w-[50%] flex gap-8 justify-end ">
                      <div className="relative w-full">
                        <input
                          type="text"
                          onKeyDown={(event) => {
                            if(event.key === "Enter")
                              dispatch(fetchAllBudgetReqs({ status: tab, limit: limit || 10, searchTerm:searchTerm, currentPage:1 }))
                          }}
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          placeholder="Search Account Number, Account Name, Amount"
                          className="border border-solid border-neutral-200 text-[14px] font-poppins rounded-lg w-full  focus:border-blue-500 focus:outline-none p-2  "
                        />
                        {searchTerm?.length ?<div title="Clear All" onClick={() =>{
                            setSearchTerm("")
                            dispatch(fetchAllBudgetReqs({ status: tab, limit: limit || 10, searchTerm:"", currentPage:1 }));
                          }} 
                          className="flex absolute items-center right-[-10px] top-2.5 ">
                          <MdCancel className="text-red-600 w-5 h-5 cursor-pointer"/>
                        </div>:""}
                      </div>
                      <div className="flex items-center ">
                        <button
                          className="bg-red-600 p-2.5 text-white font-semibold text-[14px] hover:bg-red-700  rounded-md cursor-pointer "
                          onClick={handleSearch}  
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                nextCallback={handleNextCall}
                tableHeaders={budgetRequestsHeaders}
                enableGlobalFilter={true}
                data={budgets?.budgetRequests || []}
                callback={() => {}}
                // muiTableRowProps="custom-row"
                colSizes={[100, 100, 100, 100, 100]}
                actionButtons
              />
            </div>
          </section>
        {/* </div> */}
        {/* </div> */}
      <MyComponent
        isOpen={modalOpen}
        onClose={handleCloseModal}
        // data={modalData}
      />
      <BudgetIsbnModal 
        showModal={titleModal}
        setShowModal={setTitleModal}
      />
    </div>
  
  );
};

export default BudgetRequest;
