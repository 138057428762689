import { useNavigate } from "react-router-dom";
import { Dropdown } from "../dropdown/DropDown";
import {
  emptyLogout,
  logoutUser,
  userProfile,
} from "../../redux/reducers/profileReducer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AppDispatch } from "../../redux/store";
import { user } from "../../redux/reducers/UserReducer";
import { MdLogout } from "react-icons/md";
import { IoIosArrowDown, IoIosHelpCircle } from "react-icons/io";
import { onMouseOutChangeTrue, onMouseOverChangeFalse, role, showRole, dontShowRole } from "../../redux/reducers/dropdownReducer";
import { SiTicktick } from "react-icons/si";
import axios from "axios";

const Profile = () => {
  const navigate = useNavigate();

  const Role = useSelector(role);

  const profileUser = useSelector(userProfile);
  const userData = useSelector(user);
  const dispatch = useDispatch<AppDispatch>();
  
  useEffect(() => {
    if(Number(profileUser.statusCode) === 401)
    {  {
        if(profileUser.status == 'failed') 
          {
            localStorage.removeItem("token")
            toast.dismiss()
            toast.error(profileUser.message);
          }  
      }
    }
    if(profileUser.logout?.status === 200){
        if(profileUser.logout.message?.length)
          {
            if(profileUser.logout){
              toast.dismiss()
              axios.defaults.headers['authentication'] = '';
              toast.success(profileUser.logout.message);
              dispatch(emptyLogout())
            }
            navigate("/login");
          }
        }
  },[profileUser])
  
  const logout = () => {
    dispatch(logoutUser());
  };
  
  return (
    <div className="flex items-center  max-md:w-full z-10 ">
      <Dropdown.container
        id="profile"
        icon={
          <div className="flex gap-2 items-center max-md:gap-1">
            {/* <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/38703a890757dced7ba8292f4fbb66a3e9e451a0d33e25052a1da5bd0125c50d?apiKey=a88c36c4e69e48eb98243259c870fe00&&apiKey=a88c36c4e69e48eb98243259c870fe00"
              alt="Profile picture of John"
              className="object-contain rounded-full aspect-square w-[50px] "
            /> */}
            {/* <FaUser/> */}
            <div className="flex flex-col md:w[">
              <div className="font-extrabold text-slate-700 md:text-sm capitalize">
                {userData?.first_name}
              </div>
              {/* <div className="text-slate-400 md:text-sm">
                {userData?.role?.map(role => `${role} ${}`)}
              </div> */}
            </div>
            <IoIosArrowDown />
          </div>
        }
        className=" w-[180px] right-[-26px] top-9 text-sm rounded-lg shadow-3xl "
        onMouseOutDependency={() => {
          dispatch(dontShowRole())
        }}
      >
        <Dropdown.item className={`${Role ? "bg-gray-200":"bg-white"} hover:bg-gray-100`} onClick={() => dispatch(showRole())} onMouseOut={() => dispatch(onMouseOutChangeTrue())} onMouseOver={() => dispatch(onMouseOverChangeFalse())}>
              <div className="relative flex gap-3">
                  <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H6Zm7.25-2.095c.478-.86.75-1.85.75-2.905a5.973 5.973 0 0 0-.75-2.906 4 4 0 1 1 0 5.811ZM15.466 20c.34-.588.535-1.271.535-2v-1a5.978 5.978 0 0 0-1.528-4H18a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2h-4.535Z" clip-rule="evenodd"/>
                  </svg>
                  <div className=" text-slate-700" >View Roles</div>
                  {/* <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/d7ed4be7d19454579b3192cd68edfa7710098bb61c66eecfd2c6ffb1c03297ca?apiKey=a88c36c4e69e48eb98243259c870fe00&&apiKey=a88c36c4e69e48eb98243259c870fe00"
              alt="Profile actions"
              className="object-contain aspect-[1.5] w-[9px] md:w-[7px]"
            /> */}
            <IoIosArrowDown />
              </div>
            </Dropdown.item>
            {Role && (
              <ul className={` p-0 m-0 block  border-4 border-green-900 `} style={{ borderBottom: '1px solid rgb(234 236 240)'}}>
                {userData?.role?.map((role, index) => (
                  // <Dropdown.item className="bg-green-900">
                    <li className="capitalize p-[10px] px-4 list-none border-b border-black flex gap-2" key={index}  >
                        <SiTicktick className="w-4 h-4"/>
                        {role}
                    </li>
                  // </Dropdown.item>
                ))}
              </ul>
              )}
        <Dropdown.item className="bg-gray-300 cursor-not-allowed">
          <div className="flex gap-3">
          <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
            <path fill-rule="evenodd" d="M12 20a7.966 7.966 0 0 1-5.002-1.756l.002.001v-.683c0-1.794 1.492-3.25 3.333-3.25h3.334c1.84 0 3.333 1.456 3.333 3.25v.683A7.966 7.966 0 0 1 12 20ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10c0 5.5-4.44 9.963-9.932 10h-.138C6.438 21.962 2 17.5 2 12Zm10-5c-1.84 0-3.333 1.455-3.333 3.25S10.159 13.5 12 13.5c1.84 0 3.333-1.455 3.333-3.25S13.841 7 12 7Z" clip-rule="evenodd"/>
          </svg>


            <div className=" flex text-slate-700">View Profile</div>
          </div>
        </Dropdown.item>
        {/* <Dropdown.item className="">
          <div className="flex flex-col">
            <div className=" text-slate-700">Account Settings</div>
          </div>
        </Dropdown.item> */}
        <Dropdown.item className="hover:bg-gray-100"  onClick={logout}>
          <div className="flex gap-3" >
            <MdLogout className="w-6 h-6 text-black"/>
            <div
              className=" text-slate-700"
             
            >
              Log Out
            </div>
          </div>
        </Dropdown.item>
        <Dropdown.item className="bg-gray-300 cursor-not-allowed">
          <div className="flex gap-3">
          <IoIosHelpCircle className="h-6 w-6 flex text-center justify-center items-center"/>
            <div className=" text-slate-700">Help & Support</div>
          </div>
        </Dropdown.item>
      </Dropdown.container>
    </div>
  );
};

export default Profile;
