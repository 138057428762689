import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { passwordPattern } from '../../../constants/validationPatterns';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkForgotToken, emptyforgotPassObj, forgotPassData, setPassword } from '../../../redux/reducers/forgot-passwordReducer';
import { toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from 'react-icons/fa';


interface FormValues {
  password: string;
  confirmPassword: string;
}

const ResetPasswordForm: React.FC = () => {
  const [eyeState,setEyeState] = useState(false)
  const [eyeState2,setEyeState2] = useState(false)
  const {
    watch,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onBlur', 
  });

  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const forgotData = useSelector(forgotPassData)


  useEffect(() => {
    const token = location.pathname.split("/")[location.pathname.split("/").length - 1];
    dispatch(checkForgotToken({token : token}))
  },[])

  useEffect(() => {
    //reset password check if token is valid
    if(forgotData.checkToken){
      if(forgotData.checkToken.status === 403){
        toast.dismiss()
        navigate("/login");
        toast.error(forgotData.checkToken.message)
        dispatch(emptyforgotPassObj())
        return;
      }else if(forgotData.checkToken.status === 401){
        toast.dismiss();
        navigate("/login");
        toast.error(forgotData.checkToken.message)
        dispatch(emptyforgotPassObj())
        return;
      }
    }
    // if password is setted navigate to login page
    
    if(forgotData.setPass)
      if(forgotData.setPass.status == 200){
        toast.dismiss();
        toast.success(forgotData.setPass.message)
        navigate("/login")
        dispatch(emptyforgotPassObj())
      }
  },[forgotData])

  useEffect(() => {
    const token = location.pathname.split("/")[location.pathname.split("/").length - 1];
    dispatch(checkForgotToken({token : token}))
  },[])

  const onSubmit = (data : any) => {
    data['token'] = location.pathname.split("/")[location.pathname.split("/").length - 1]
    dispatch(setPassword(data))
  };

  const checkPassword=()=>{
    if(watch("password")!==watch("confirmPassword")){
        setError("confirmPassword",{message:"Password did not match"})
    }
    else if(watch("password")===watch("confirmPassword")){
        setError("confirmPassword",{message:""})
    }
  }
  
  return (
    <div className="flex flex-col  ">
      <div className="text-black flex flex-col text-center">
        <span className="font-bold text-[40px]">Reset Password</span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5">
         
        <div className="relative flex flex-col text-base rounded-md text-zinc-400">
            <Controller
              name="password"
              control={control}
              rules={{
                required: "Password is required",
                pattern:passwordPattern
              
              }}
              render={({ field }) => (
                <input
                  {...field}
                  type={eyeState ? "text":"password"}
                  id="password"
                  placeholder="Password"
                  className="py-3 px-4 text-sm font-poppins rounded-md  bg-zinc-100"
                />
              )}
            />
            {watch("password")?.length ? 
              <div className="absolute flex top-2 right-3"  onClick={() => setEyeState(!eyeState)}> 
              {eyeState ? 
                    (<div className="text-gray-800 dark:text-white border rounded
                      ">
                      <span className="cursor-pointer px-2  " ><FaEye/> </span>
                    </div>)
                    : 
                    (<div className="text-gray-800 dark:text-white border rounded
                      ">
                      <span className="cursor-pointer px-2  " ><FaEyeSlash/> </span>
                    </div>   )   
              }
              </div>
              :""
              } 
            {errors?.password && <span className="text-red-500 text-sm flex flex-wrap  max-w-[300px] ">{errors.password.message}</span>}
        </div>
        <div className="relative flex flex-col text-base rounded-md text-zinc-400">
          {/* <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
          </label> */}
          <Controller
            name="confirmPassword"
            control={control}
            rules={{
              required: "Confirm password is required",
             onChange:({target})=>checkPassword()
            }}
            render={({ field }) => (
              <>
              
              <input
                {...field}
                type={eyeState2 ? "text":"password"}
                id="confirmPassword"
                placeholder="Confirm Password"
                className="py-3 px-4 text-sm font-poppins rounded-md  bg-zinc-100"
              />
               {errors.confirmPassword && <span className="text-red-500 text-sm flex flex-wrap  max-w-[300px] ">{errors.confirmPassword.message}</span>}
              </>
            )}
          />
            {watch("confirmPassword")?.length ? 
              <div className="absolute flex top-2 right-3"  onClick={() => setEyeState2(!eyeState2)}> 
              {eyeState2 ? 
                    (<div className="text-gray-800 dark:text-white border rounded
                      ">
                      <span className="cursor-pointer px-2  " ><FaEye/> </span>
                    </div>)
                    : 
                    (<div className="text-gray-800 dark:text-white border rounded
                      ">
                      <span className="cursor-pointer px-2  " ><FaEyeSlash/> </span>
                    </div>   )   
              }
              </div>
              :""
            } 
          {/* {errors.confirmPassword && <span className="text-red-500 text-sm flex flex-wrap  max-w-[300px]">{errors.confirmPassword.message}</span>} */}
        </div>
        <div className="flex flex-col  text-base rounded-md text-zinc-400">
          <button
            type="submit"
            className="flex items-center justify-center 
        font-bold  text-[17px] text-white rounded-md w-full
         max-w-lg cursor-pointer bg-red-600 border border-red-600 py-3 px-4 transition-colors duration-300 hover:bg-red-700"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordForm;
