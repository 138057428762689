import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { useNavigate, useParams } from 'react-router-dom';
import { getSelectedInvoice, PostmatchInvoices } from '../../redux/reducers/InvoiceReducer';
import { MdPendingActions, MdVerified } from 'react-icons/md';

const InvoicePreview: React.FC= ({ }) => {
  const user = useSelector((state:RootState) => state.UserReducer.userData)
  const navigate= useNavigate()
const dispatch = useDispatch<AppDispatch>();
const {id} = useParams();
const invoices = useSelector((state:RootState)=>state.invoiceReducer.uploadedFile);

useEffect(() => {
  if(id){
    dispatch(getSelectedInvoice({ _id: id }));
  }
}, [id]);

const handlematch=(data:any)=>{
  dispatch(PostmatchInvoices(data))
}


  return (
    <article className="flex flex-col  rounded-2xl bg-slate-200  p-2 h-auto ">
      <header className="px-2 w-full text-lg font-semibold text-neutral-800 ">
        Invoice Preview
      </header>
      <section className="flex flex-col mt-3 w-full text-base max-md:max-w-full">
        <iframe 
          loading="lazy" 
       src={invoices.Invoice_URL}
          // alt="Invoice preview" 
          // scrolling="no" 
          className="object-contain self-center max-w-full rounded-2xl aspect-[0.84] w-[466px] h-auto
           iframe-container" 
          // frameBorder="0"
          // className="object-contain self-center max-w-full rounded-2xl aspect-[0.84] w-[466px]" 
        />
        <footer className="flex flex-col justify-center p-6  rounded-none bg-slate-200 max-md:max-w-full">
          {invoices.Invoice_status === 0 
            ? 
          user?.role?.includes('approver') 
            ? 
          <div className="flex  items-center max-md:max-w-full gap-3 ">
            {/* <ActionButton 
              text="Decline" 
              variant="secondary" 
            /> */}
            <button className="cursor-pointer flex-1 shrink self-stretch font-medium whitespace-nowrap basis-0 py-3 px-5 font-poppins rounded-md bg-transparent text-black border border-solid border-gray-400 " onClick={()=>{
              navigate("/invoices")
              handlematch({ id:id,match:false})
            }}
            >
               Decline
            </button>
            <button className="flex-1 cursor-pointer shrink self-stretch font-medium whitespace-nowrap basis-0 py-3 px-5 font-poppins rounded-md bg-green-600 text-white" onClick={()=>{
              navigate("/invoices")
              handlematch({id:id, match :true})
            }}
              >Mark as Verified

            </button>


          </div>
            :
          <div className='text-yellow-500 flex items-center justify-center font-semibold text-[18px]'>
            <MdPendingActions  className='w-[30px] h-[30px]'/>  Pending
          </div>
            :
          invoices.Invoice_status === 1 
            ? 
          <div className='text-green-500 flex items-center justify-center font-semibold text-[18px]'>
            <MdVerified className='w-[30px] h-[30px]'/>  Verified
          </div>
           : 
          <div className='text-red-500 flex items-center justify-center font-semibold text-[18px]'>
            <svg fill='red' baseProfile="tiny" className='text-red-600' height="30px" version="1.2" viewBox="0 0 24 24" width="30px"  xmlns="http://www.w3.org/2000/svg" ><path className='bg-red-500' d="M12,4c-4.411,0-8,3.589-8,8s3.589,8,8,8s8-3.589,8-8S16.411,4,12,4z M7,12c0-0.832,0.224-1.604,0.584-2.295l6.711,6.711  C13.604,16.776,12.832,17,12,17C9.243,17,7,14.757,7,12z M16.416,14.295L9.705,7.584C10.396,7.224,11.168,7,12,7  c2.757,0,5,2.243,5,5C17,12.832,16.776,13.604,16.416,14.295z"/></svg>
            Declined
          </div>}
          </footer>
       </section>
</article>

  );
};

export default InvoicePreview;