import React from 'react';
import DashboardItem from './DashboardItem';
import { navItems } from './navbarConstants';
import { useSelector } from 'react-redux';
import { user } from '../../redux/reducers/UserReducer';
import Abrams from '../../assets/images/Abrams.png'
import logo from '../../assets/images/logo.png'


const Sidebar: React.FC = ({  }) => {
  const userData = useSelector(user)
  // console.log(userData)
  return (
    <aside className={`grid h-[100vh]  border-gray-300 
    border-solid border-r-[1px] w-full  `} id={"sidebar-id"}>
      <div className="text-[15px]  border-r  border-gray-200 ">
        <div className="flex flex-col flex-1 shrink basis-0 h-full">
          <div className="flex flex-col pt-6 overflow-hidden items-start ">
          <div className="logo">
          <div className="hidden lg:block">
              <img
                loading="lazy"
                src={Abrams}
                alt="Abrams Logo"
                className="object-contain w-[350px] h-[60px] max-w-full"
              />
            </div>
            <div className="hidden md:block lg:hidden">
              <img
                loading="lazy"
                src={logo}
                alt="Logo"
                className="object-contain w-[70px] h-[40px] "
              />
              </div>
</div>


         
            <nav className="flex flex-col px-3 mt-4 leading-loose text-zinc-500 w-full">
                {navItems.map((nav) => (
                  userData?.role?.includes('admin')
                    ? 
                  <DashboardItem path={nav.path} svg={nav.svg} alt={nav.alt} label={nav.label} /> 
                    :
                  userData?.role?.includes('approver') && (nav.path !== "/newBudgetReq" && nav.path !== "/usermanage")
                    ? 
                  <DashboardItem path={nav.path} svg={nav.svg} alt={nav.alt} label={nav.label} /> 
                    :
                  userData?.role?.includes('requestor') && nav.path !== "/usermanage" 
                    ?
                  <DashboardItem path={nav.path} svg={nav.svg} alt={nav.alt} label={nav.label} /> 
                    :
                  <></>
                ))}
            </nav>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
