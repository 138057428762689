import React from 'react'
import TopBar from '../common-components/topbar/TopBar'
import { Outlet, useLocation } from 'react-router-dom'
import Sidebar from '../common-components/navbar/SideBar'

const Layout = () => {
  const location = useLocation();
  const hideNavbar = ['/login', '/forgot-password',].includes(location.pathname) || location.pathname.split("/").includes('onBoarding') || location.pathname.split("/").includes('onboarding') || location.pathname.split('/').includes('reset-password') || location.pathname.split("/").includes('landing');
  
  return (
    <div className='w-full font-poppins'>
      <div className="overflow-hidden w-full bg-slate-50 ">
      <main className="flex  w-full overflow-hidden">
        {! hideNavbar && <div className="flex max-md:flex-col">
          <Sidebar />
        </div>}
        <div className='flex flex-col overflow-hidden h-[100vh] w-full'>
          {! hideNavbar && <div className='w-full  '><TopBar /></div>}
          <div className='overflow-y-auto w-full '>
            <Outlet />
          </div>
        </div>
      </main>
    </div>
    </div>
  )
}

export default Layout