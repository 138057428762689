import * as React from "react";
import Abrams from '../../assets/images/Abrams.png';
import { useNavigate } from "react-router-dom";
import landing from '../../assets/images/landing.png';

const Landing: React.FC = () => {
    const navigate = useNavigate();
    return (
        <main className="h-screen w-screen flex flex-col font-poppins">
            <header className="w-full bg-gray-100 flex items-center justify-center">
                <img src={Abrams} alt="Abrams" className="h-[90px]" />
            </header>
            <div className="flex flex-1 flex-col md:flex-row lg:flex-row items-center justify-center gap-6 md:p-6  ">
                <div 
                    style={{ 
                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.5)"  ,
                    }} 
                    onClick={() => navigate('/login')}
                    className=" relative w-full md:w-[400px] lg:w-[500px] h-[250px] md:h-[300px] lg:h-[300px] flex items-center justify-center border border-red-500 
                     text-[18px] md:text-[36px] font-medium flex-col p-2 md:p-4 cursor-pointer "
                >
                    <img src={landing} alt="Landing" className="h-full w-full object-contain" />
                    <div className="bg-red-600 text-white text-[20px] md:text-[30px] flex items-center justify-center  w-full absolute bottom-0  ">
                        COOP
                    </div>
                </div>
                <div 
                    style={{ 
                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.5)"  
                    }} 
                    className="w-full md:w-[400px] lg:w-[500px] h-[250px] md:h-[300px] lg:h-[300px] flex items-center justify-center border border-red-500 
                     text-[18px] md:text-[36px] font-medium flex-col p-2 md:p-4"
                >
                    <div>SEG</div>
                    <div className="text-[12px] md:text-[15px]">
                        Coming soon
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Landing;
