import { useForm, Controller } from "react-hook-form";
import DatePicker from "./DatePicker";
import TextArea from "./TextArea";
import SubmitButton from "./SubmitButton";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { useEffect, useRef, useState } from "react";
import { emptyAccountBudget, emptyIsbnsString, emptyMatchedIsbns,
   accountsData, addNewbudgetReq, budgetData, emptyBudgetReq, fetchAccounts, 
   fetchIsbnList, fetchIsbnsByCsv, ISBNData, setIsbnString, fetchIsbnByCommaString,
    checkAccountBudget, accountbudget, updateUploadLoading } from "../../redux/reducers/budgetreqReducer";
import Select, {components, SelectInstance} from 'react-select'
import { fetchUsers, user, Users } from "../../redux/reducers/UserReducer";
import { useNavigate } from "react-router-dom";
import UnmatchedIsbnModal from "./UnmatchedIsbnModal";
import { positiveNumberPattern } from "../../constants/validationPatterns";
import BudgetIsbnModal from "../budgetRequest/budgetIsbnModal";
import { MdCancel, MdOutlineUploadFile } from "react-icons/md";
import { FaAngleDown, FaInfoCircle } from "react-icons/fa";
import { Dropdown } from "../../common-components/dropdown/DropDown";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import InputField from "../invoices/InputField";
import { formatCurrency } from "../../common-components/commonFunctions";

interface BudgetRequestProps {}

interface optionObj {
  value: string;
  label: string;
}

interface FormValues {
  claim_number:string;
  account_name: optionObj;
  account_number: optionObj;
  isbn: optionObj[];
  title: optionObj[];
  coopType: optionObj;
  startDate: Date;
  endDate: Date;
  deadline: Date;
  description: string;
  notes: string;
  required_amount_budget: number | null;
  approver: optionObj[];
  isApproverRequired: boolean;
  required_approval:number;
  codeas?:optionObj;
}

const BudgetRequest: React.FC<BudgetRequestProps> = () => {

  const navigate = useNavigate();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const amountBudgetRef = useRef<HTMLInputElement>(null);
  const [amountBudgetInputShow,setAmountBudgetInputShow] = useState<boolean>(false);

  const { handleSubmit, control, watch, setValue, formState:{errors}, setError, getValues } = useForm<FormValues>();
  const [unMatchedIsbnModal, setUnMatchedIsbnModal] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const accounts = useSelector(accountsData);
  const profile = useSelector(user);
  const users = useSelector(Users)    // account user
  const isbns = useSelector(ISBNData)   // isbn details
  const accountBudget = useSelector(accountbudget);
  const isbnsString = useSelector((state:RootState) => state.budgetReducer.isbns?.isbnsString)
  const budgetLoading = useSelector((state:RootState) => state.budgetReducer.isLoading);

  const isbnLimit = 15;
  const [isbnSkip,setIsbnSkip] = useState(0);

  const [accountsPage,setAccountsPage] = useState(1);
  const [account_num_string,set_Account_Num_String] = useState<string | number | null>(null);
  const accountsLimit = 10;
  
  const [isbnModal, setIsbnModal] = useState(false);
  
  const budget = useSelector(budgetData); // whole budget reducer data

  // coop type data
  const coopTypes = [
    // {value:"Standard (disabled)",label:"Standard (disabled)"},
    {value:"Incremental",label:"Incremental"},
    {value:"Standard",label:"Standard"}
    // {value:"Incremental – Author Events (disabled)",label:"Incremental – Author Events (disabled)"}
  ]

  const codeAsOptions = [
    {value:"TCOOP23",label:"TCOOP23"},
    {value:"TCOOP24",label:"TCOOP24"},
    {value:"TCOOP25",label:"TCOOP25"},
  ]

  // user account name for select and search field
  const account_name_options = accounts?.accounts?.map(account => ({
    value: account._id ?? '',
    label: account.Account_name ?? '',
  }));

  // user account number for select and search field
  const account_number_options = accounts?.accounts?.map(account => ({
    value: account._id ?? '',
    label: account.Account_number ?? '',
  }));

  // approver user for multi select field
  const userApproverOptions = users?.map(user => ({
    value: user._id ?? '',
    label: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
  }));

  // isbn number for select and search field
  const [isbn_number_options,set_ISBN_NUMBER_OPTION] = useState<optionObj[]>([])

  // isbn title for select and search field
  const [isbn_title_options,set_ISBN_TITLE_OPTION] = useState<optionObj[]>([])

  const [codeasObj,setCodeasObj] = useState(codeAsOptions[1])
  const [coopTypeObj,setCoopTypeObj] = useState(coopTypes[0])
  const [clearModal, setClearModal] = useState<boolean>(false)

  // fetched all accounts
  useEffect(() => {
    setValue("codeas",codeAsOptions[1])
    setValue("coopType",coopTypes[0])
    dispatch(fetchIsbnList({limit : isbnLimit, skip : isbnLimit * isbnSkip, selectedData : watch('isbn')?.length ? watch('isbn').map(isbn => isbn.label) : []}))
    dispatch(fetchAccounts({limit:accountsLimit,currentPage:accountsPage}))
    dispatch(fetchUsers({role:['approver']}))
    if(profile?.role?.includes("approver"))  
      setValue('approver',[{value:profile._id,label:`${profile.first_name} ${profile.last_name}`}] as optionObj[])
    return () => {
      dispatch(emptyBudgetReq())
      dispatch(emptyAccountBudget())
    }
  },[])

  const isbnElement = document.getElementById("isbn-selector")
  const titleElement = document.getElementById("title-selector")
  // const titleRef = useRef<SelectInstance<optionObj, true> | null>(null);
  
  useEffect(() => {
    if (isbnElement) {
      const inner_1st_Div = isbnElement.querySelector('div > div');
      // Find the inner div within the outer div
      
      if(inner_1st_Div) {
        const innerMostDiv = inner_1st_Div?.querySelector('div > div')
        if(innerMostDiv && innerMostDiv.scrollWidth) innerMostDiv.scrollLeft = innerMostDiv?.scrollWidth
      }

    }

    if (titleElement) {
      // Find the inner div within the outer div
      const inner_1st_Div = titleElement.querySelector('div > div');
      
      if(inner_1st_Div) {
        const innerMostDiv = inner_1st_Div?.querySelector('div > div')
        if(innerMostDiv && innerMostDiv.scrollWidth) innerMostDiv.scrollLeft = innerMostDiv?.scrollWidth
      }

    }

    // if (multiValuContainer[0] || multiValuContainer[1]) {
    //   multiValuContainer[0].scrollLeft = multiValuContainer[0].scrollWidth;
    //   multiValuContainer[1].scrollLeft = multiValuContainer[1].scrollWidth;
    // }
  // },[multiValuContainer[0]?.scrollWidth])
  },[isbnElement?.querySelector('div > div')?.querySelector('div > div')?.scrollWidth,titleElement?.querySelector('div > div')?.querySelector('div > div')?.scrollWidth])
  
  // setting the values of all matched isbns 
  useEffect(() => {

    if(isbns?.list?.length){
      set_ISBN_NUMBER_OPTION(
        isbns?.list?.map(isbn => ({
          value: isbn.ISBN ?? '',
          label: isbn.ISBN ?? '',
        }))
      )
      set_ISBN_TITLE_OPTION(
        isbns?.list?.map(isbn => ({
          value: isbn.ISBN ?? '',
          label: isbn.FULL_TITLE ?? '',
        }))
      )
    }
    if(isbns?.matchedIsbns?.length){
      // converting array of matchedisbns to select value,label array objects
      const isbnArrayObj = isbns?.matchedIsbns.map(isbn => {return({value : isbn.ISBN ?? '', label : isbn.ISBN ?? ''})});
      const titleArrayObj = isbns?.matchedIsbns.map(isbn => {return({value : isbn.ISBN ?? '', label : isbn.FULL_TITLE ?? ''})});
      
      // pushing data in array obj if exists
      if(watch('isbn')?.length){ 
        isbnArrayObj.push(...watch('isbn'))
        titleArrayObj.push(...watch('title')) 
      }
      // setting the value with mathched csv data
      setValue('isbn', isbnArrayObj || []);
      setValue('title',titleArrayObj || []);

      // emptying the matchedIsbns state
      dispatch(emptyMatchedIsbns())
    }
    if(budget.addBudget?.status === 201)
    {
      toast.dismiss()
      toast.success(budget.addBudget.message);
      dispatch(emptyBudgetReq())
      navigate("/budgetReq")
    }
    if(isbns?.unMatchedIsbns?.length){
      setUnMatchedIsbnModal(true);
    }
    if(accountBudget?.status === 403){
      setError("required_amount_budget",{message:accountBudget.message})
    }else if(accountBudget?.status === 200) {
      setError("required_amount_budget",{})
      dispatch(emptyAccountBudget())
    }
  },[isbns,budget,accountBudget])

  // isbns on isbn field scroll
  useEffect(() => {
    if(watch('isbn')?.length){ 
      const selectedData = watch('isbn').map(isbn => isbn.label);
      dispatch(fetchIsbnList({limit : 1, skip : (isbnLimit * (isbnSkip != 0 ? isbnSkip : 1)) + watch('isbn')?.length, selectedData : selectedData || []}))
    }
  },[watch('isbn')])

  useEffect(() => {
    if(amountBudgetInputShow)
      amountBudgetRef.current?.focus();
  },[amountBudgetInputShow])

  // selecting the account number or account name related to each other
  const changeAccountInfo = (event:any) => {

    setError("account_name",{})
    setError("account_number",{})
    setError("required_amount_budget",{})
    
     // setting the isbn number if isbn title changed
    if(event.target.name === 'account_name'){
      if(account_number_options)  {
        const filteredAccountNumber = account_number_options.filter((account : optionObj) => account.value === watch("account_name").value)[0] 
        setValue('account_number', { value: filteredAccountNumber.value, label: filteredAccountNumber.label } as optionObj);
      }}
    // setting the isbn title if isbn number is changed
    else if(event.target.name === 'account_number'){
      if(account_name_options)  {
        const filteredAccountName = account_name_options.filter((account : optionObj) => account.value === watch("account_number").value)[0] 
        setValue('account_name', { value: filteredAccountName.value, label: filteredAccountName.label } as optionObj);
      }}
    if(watch("required_amount_budget"))
      dispatch(checkAccountBudget({amount : watch("required_amount_budget"), account_id:watch("account_number").value}))
  }

  // selecting the isbn number or isbn name related to each other
  const changeIsbnInfo = (event:any) => {
    // if(Array.isArray(event.target.value))
    //   {
          // setting the isbn number if isbn title changed
          if(event.target.name === 'title' ){
            const titleIsbns = watch("title").map((title:any) => title.value)
            const isbnFilteredData = isbn_number_options?.filter(isbn => titleIsbns.includes(isbn.value)) || []
            setValue('isbn', isbnFilteredData );
            setError("isbn",{})
          }
          // setting the isbn title if isbn number is changed
          else if(event.target.name === 'isbn'){
            const isbnsList = watch("isbn").map((isbn:any) => isbn.value)
            const titleFilteredData = isbn_title_options?.filter(title => isbnsList.includes(title.value)) || []
            setValue('title', titleFilteredData);
            setError("title",{})
          }
    // }
  }
  
  // submitting the form data
  const onSubmit = (data: any) => {
    data['account_id'] = data['account_name'].value
    data['account_name'] = data['account_name'].label
    data['account_number'] = data['account_number'].label
    data['isbn'] = data['isbn'].map((isbn:any) => Number(isbn.label))
    data['title'] = data.title.map((title:any) => title.label)
    data['coopType'] = data['coopType'].label
    data['codeas'] = data['codeas'].label
    const amountBudget = watch('required_amount_budget')

    if(watch('required_amount_budget')){
      
      if(isNaN(Number(amountBudget))){
        setError("required_amount_budget",{message:"Please Enter Number Only"})
        return
      }  

      if(Number(amountBudget) <= 0){
        setError("required_amount_budget",{message:"Please enter number greater than 0."})
        return
      }
    }


    if(accountBudget?.status===403){
      setError("required_amount_budget",{message:accountBudget.message})
      return
    }
    if(watch("endDate"))
      if(new Date(watch("startDate")) > new Date(watch("endDate"))){
            setError("endDate",{message:"End date is less than start date"})
            return
      }

      data['required_amount_budget'] = amountBudget
      data["required_approval"] = data["required_approval"] ?? false;
      if(data?.approver && data.approver?.length) 
        data['approver'] = data['approver'].map((approver:any) => approver.value)
      dispatch(addNewbudgetReq(data))
  };

  // fetching the isbn number and title after uploading the csv file
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const formdata = new FormData();
    if (file) {
      formdata.append('isbn_file',file);
      if(watch('isbn')?.length)
        watch('isbn').map(isbn => formdata.append('selectedData',isbn.label))
      dispatch(updateUploadLoading())
      dispatch(fetchIsbnsByCsv(formdata))
      if(fileInputRef.current) fileInputRef.current.value = '';
    }
  }

  const handleSVGClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const checkDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const startDate = new Date(watch("startDate"))
    const endDate= new Date(watch("endDate"))
    
    if(startDate > endDate){
    if(event.target.name === "startDate")
      setError("startDate",{message:"Start date is greater than end date"})
    else if(event.target.name === "endDate"){
      if(event.target.value)
        setError("endDate",{message:"End date is less than start date"})
      else 
        setError("endDate",{})
    }
    }else if(startDate < endDate){
        setError("startDate",{})
        setError("endDate",{})
    }
  }

  const handleCoopCodeasChange = (event:any) =>{
    if(event.target.name === "coopType")
      setCoopTypeObj(event.target.value);
    else if(event.target.name === "codeas"){
      setCodeasObj(event.target.value);
      setValue("codeas",event.target.value)
    }
  }

  return (
    <div className="overflow-auto">
      <section className="flex flex-col text-sm text-zinc-950 mx-8">
        <div className=" flex-col text-zinc-950  ">
          <main className=" font-poppins shadow-xl">
            <h1 className="text-lg font-semibold text-neutral-800 ">
              Create Budget Request
            </h1>
            <form className="flex flex-col bg-white p-4 " onSubmit={handleSubmit(onSubmit)}>
              <section>
                <div className="flex flex-col gap-4">
                  {/* <p className="font-bold text-[16px] m-0">Account Info</p>
                  <div className="h-[1px] bg-slate-400 mb-4" > </div> */}

                  <div className="grid grid-cols-1 sm:grid-cols-3 w-full gap-3  ">
                    <Controller
                      control={control}
                      name="account_name"
                      rules={{
                        onChange:changeAccountInfo,
                        required:"Account name is required"
                      }}
                      render={({ field: { onChange, onBlur, value, name, ref} }) => (
                        <div className=" relative flex flex-col bg-white w-full whitespace-nowrap">
                          <label className="text-sm leading-none mt-2 text-neutral-800 py-2 font-bold">Account Name <span className="text-red-500">*</span></label>
                          <Select 
                              ref={ref}
                              placeholder={"Select Account Name"}
                              name={name} 
                              onMenuScrollToBottom={() => {
                                setAccountsPage(old => old += 1);
                                dispatch(fetchAccounts({limit:accountsLimit,currentPage:(accountsPage+1),selectedAccount_id:watch("account_number")?.value || undefined,account_name:account_num_string}))
                              }}
                              onInputChange={(target) => {
                                set_Account_Num_String(target)
                                dispatch(fetchAccounts({limit:accountsLimit,currentPage:1,selectedAccount_id:watch("account_number")?.value || undefined,account_name:target}))
                              }}
                              onBlur={onBlur}
                              value={value}
                              styles={{
                                valueContainer:(props) => ({
                                  ...props,
                                  fontSize:"14px"
                                })
                              }}
                              noOptionsMessage={() => 'Account name not found'}
                              onChange={(selectedOptions) => onChange(selectedOptions)}
                              options={account_name_options} 
                            />
                            {errors.account_name && <span className=" absolute text-red-500 bottom-[-25px]">{errors.account_name.message}</span>}
                        </div>
                      )}
                    />
                    <Controller
                      control={control}
                      name="account_number"
                      rules={{
                        onChange:changeAccountInfo,
                        required:"Account number is required"
                      }}
                      render={({ field: { onChange, onBlur, value, name, ref} }) => (
                        <div className="relative flex flex-col bg-white w-full whitespace-nowrap">
                          <label className="text-sm leading-none mt-2 text-neutral-800 py-2 font-bold">Account Number <span className="text-red-500">*</span></label>
                          <Select 
                              ref={ref}
                              onMenuScrollToBottom={() => 
                                {
                                  setAccountsPage(old => old += 1);
                                  dispatch(fetchAccounts({limit:accountsLimit,currentPage:(accountsPage+1),selectedAccount_id:watch("account_number")?.value || undefined,account_num:account_num_string}))
                                }}
                              placeholder={"Select Account Number"}
                              name={name} 
                              onBlur={onBlur}
                              value={value}
                              onInputChange={(target) => {
                                set_Account_Num_String(target)
                                dispatch(fetchAccounts({limit:accountsLimit,currentPage:1,selectedAccount_id:watch("account_number")?.value || undefined,account_num:target}))
                              }}
                              styles={{
                                valueContainer:(props) => ({
                                  ...props,
                                  fontSize:"14px"
                                })
                              }}
                              noOptionsMessage={() => 'Account number not found'}
                              onChange={(selectedOptions) => onChange(selectedOptions)}
                              options={account_number_options} 
                            />
                            {errors.account_number && <span className=" text-red-500 absolute bottom-[-25px]">{errors.account_number.message}</span>}
                        </div>
                      )}
                    />
                    <Controller
                        name="claim_number"
                        control={control}
                        rules={{
                          // pattern:positiveNumberPattern
                        }}
                        render={({ field }) => (
                          <div className="relative flex flex-col bg-white w-full whitespace-nowrap">
                            <label className="text-sm leading-none mt-2 text-neutral-800 py-2 font-bold">Claim Number</label>
                              <input
                                type="text"
                                placeholder="Claim Number"
                                className="p-2 border border-solid border-neutral-300  font-poppins rounded-md "
                                {...field}
                              />
                            {errors.claim_number && <span className=" text-red-500 absolute bottom-[-25px]">{errors.claim_number.message}</span>}
                          </div>
                        )}
                      />
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 items-center mt-3">
                      {/* <div className="flex w-full relative"> */}
                      <div className="w-full flex relative">
                        <Controller
                          control={control}
                          name="isbn"
                          rules={{
                            onChange:changeIsbnInfo,
                            required:'ISBN is required',
                            onBlur:() => {
                              if(isbns?.isbnsString?.length){
                                if(isbns?.isbnsString.includes(","))
                                  {
                                    const isbnsStringList = isbns?.isbnsString.includes(",") ? isbns?.isbnsString.split(",") : [isbns.isbnsString]
                                    dispatch(fetchIsbnByCommaString({ selectedData : watch('isbn')?.length ? watch('isbn').map(isbn => isbn.label) : [], isbnsStringList : isbnsStringList || []}))
                                  }
                                  dispatch(emptyIsbnsString())
                              }
                            },
                          }}
                          render={({ field: { onChange, onBlur, value, name, ref} }) => (
                            <div className="relative flex flex-col bg-white w-full">
                              {
                                watch("isbn")?.length ? (
                                  <div className="flex  absolute top-2 right-2"> 
                                    <div className="text-gray-800 dark:text-white border rounded
                                    ">
                                      <span className="cursor-pointer px-2  "  onClick={() => setIsbnModal(!isbnModal)} >
                                        {/* <FaEye/> */}
                                        <u>
                                        Preview ISBN/Titles
                                        </u>
                                      </span>
                                    </div>
                                  </div> 
                                ) : ""
                              }
                              {/* account_num_string, selectedAccounts, currentPage  , limit */}
                              <label className="text-sm leading-none text-neutral-800 py-2 font-bold">ISBN <span className="text-red-500">*</span></label>
                              <Select
                                  onKeyDown={(target) => {
                                    if(target.key === "Enter" || target.key === "Tab"){
                                      target.preventDefault();
                                      if(isbns?.isbnsString?.length){
                                        if(isbns?.isbnsString.includes(","))
                                          {
                                            const isbnsStringList = isbns?.isbnsString.includes(",") ? isbns?.isbnsString.split(",") : [isbns.isbnsString]
                                            dispatch(fetchIsbnByCommaString({ selectedData : watch('isbn')?.length ? watch('isbn').map(isbn => isbn.label) : [], isbnsStringList : isbnsStringList || []}))
                                          }
                                          dispatch(emptyIsbnsString())
                                    }}
                                  }}
                                  placeholder={"Select ISBN"}
                                  onMenuScrollToBottom={() => 
                                    {
                                      setIsbnSkip(old => old += 1);
                                      dispatch(fetchIsbnList({limit : isbnLimit, skip : (isbnSkip + 1) * isbnLimit, selectedData : watch('isbn')?.length ? watch('isbn').map(isbn => isbn.label) : [], isbnString : isbnsString}))
                                    }}
                                  isMulti 
                                  onInputChange={(value) => {
                                    dispatch(setIsbnString(value))
                                    dispatch(fetchIsbnList({limit : isbnLimit, skip : 0, selectedData : watch('isbn')?.length ? watch('isbn').map(isbn => isbn.label) : [], isbnString : value}))
                                  }}
                                  ref={ref}
                                  id="isbn-selector"
                                  name={name} 
                                  onBlur={onBlur}
                                  value={value}
                                  noOptionsMessage={() => 'ISBN not found'}
                                  components={{
                                    DropdownIndicator: () => (
                                      <div className="flex items-center justify-end">
                                        {isbns?.isUploadLoading ? 
                                        (
                                          <div role="status">
                                              <svg aria-hidden="true" className="w-6 h-6 text-gray-200 animate-spin px-1 dark:text-gray-600 fill-black flex items-center justify-center " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                              </svg>
                                          </div>
                                        )
                                        :
                                        (<div title="Upload CSV File " className="flex items-center justify-center">
                                          <svg
                                            onClick={handleSVGClick}
                                            className="px-1 text-slate-600"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8L14 2Z"
                                              stroke="currentColor"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                            <path
                                              d="M12 16V8M12 16L8 12M12 16L16 12"
                                              stroke="currentColor"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                            <path
                                              d="M16 16H8C6.9 16 6 16.9 6 18C6 19.1 6.9 20 8 20H16C17.1 20 18 19.1 18 18C18 16.9 17.1 16 16 16Z"
                                              stroke="currentColor"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        </div>)}
                                        <svg className=" pr-1 text-slate-300 dark:text-white " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 9-7 7-7-7"/>
                                        </svg>
                                        {/* {
                                          watch("isbn")?.length ? (
                                            <div className="flex "> 
                                              <div className="text-gray-800 dark:text-white border rounded
                                              ">
                                                <span className="cursor-pointer px-2  "  onClick={() => setIsbnModal(!isbnModal)} ><FaEye/> </span>
                                              </div>
                                            </div> 
                                          ) : ""
                                        } */}
                                      </div>
                                      ),
                                    MultiValueContainer:(props) => (
                                      <components.MultiValueContainer {...props}>
                                        <div className="flex">
                                          {props.children}
                                        </div>
                                      </components.MultiValueContainer> 
                                    ),
                                    IndicatorsContainer:(props) => (
                                      <components.IndicatorsContainer {...props}>
                                        <div className="flex items-center gap-3 mr-2">
                                          {watch("title")?.length ?<div title="Clear All" onClick={() => {
                                            setValue("isbn",[])
                                            setValue("title",[])
                                            // setClearModal(!clearModal)
                                          }} 
                                            className="flex items-center border-r-[1px] pr-4 border-solid border-gray-300">
                                            <MdCancel className="text-red-600 w-5 h-5 cursor-pointer"/>
                                            {/* <ConfirmDialog /> */}
                                          </div>:""}
                                          <div className="flex items-center justify-end border-r-[1px] pr-2 border-solid border-gray-300">
                                            {isbns?.isUploadLoading ? 
                                            (
                                              <div role="status">
                                                  <svg aria-hidden="true" className="w-6 h-6 text-gray-200 animate-spin px-1 dark:text-gray-600 fill-black flex items-center justify-center " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                  </svg>
                                              </div>
                                            )
                                            :
                                            (<div title="Upload CSV File " className="flex items-center justify-center">
                                              <MdOutlineUploadFile onClick={handleSVGClick} className="w-6 h-6 cursor-pointer" />

                                            </div>)}
                                      </div>
                                          <div className="flex items-center">
                                            <FaAngleDown className="text-gray-400 w-4 h-4 cursor-pointer"/>
                                          </div>
                                        </div>
                                      </components.IndicatorsContainer>
                                    )
                                  }}
                                  styles={{
                                    container:(provided) => ({
                                      // backgroundColor:"red",
                                      // width:"30vw",
                                      // maxWidth:"37vw",
                                      width:"100%",
                                      display:"flex"
                                    }),
                                    multiValue: (provided) => ({
                                      ...provided,
                                      height:"30px",
                                      display:"flex",
                                      alignItems:"center",
                                      borderRadius:"6px",
                                      minWidth:"none",
                                    }),
                                    valueContainer:(provided) =>({
                                      maxWidth:"28vw",
                                      width:"100%",
                                      paddingLeft:"8px",
                                      display:"flex",
                                      alignItems:"center",
                                      height:"40px",
                                      overflowX:"auto",
                                    }),
                                    control:(provided) => ({
                                      display:"flex",
                                      // maxWidth:"37vw",
                                      // width:"510px",
                                      height:"40px",
                                      alignItems:"center",
                                      justifyContent:"space-between",
                                      width:"100%"
                                    }),
                                    // input: (provided) => ({
                                    //   ...provided,
                                    //   display:"flex",
                                    //   alignItems:"center",
                                    //   textAlign:"center",
                                    //   justifyContent:"center",
                                    //   marginRight:"60px",
                                    //   fontSize: '16px',
                                    // })
                                  }}
                                  className="border border-solid border-neutral-200 font-poppins rounded-lg w-full text-[14px]"
                                  onChange={(selectedOptions) => onChange(selectedOptions)}
                                  options={isbn_number_options} 
                                />
                                {/* <Tags placeholder="Select ISBN" values={isbn_number_options} isMultiple/> */}
                                {/* <CustomMultiSelect /> */}
                                {errors.isbn && <span className="text-red-500 absolute bottom-[-25px]">{errors.isbn.message}</span>}
                            </div>
                          )}
                        />
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        />
                        <div className="absolute left-12 top-2">
                          <Dropdown.container id={"new_budget_search_tooltip"} className="z-10 w-[400px]"
                            icon={<FaInfoCircle className="text-blue-700"   />}>
                            <Dropdown.item className="bg-gray-200 rounded-lg">You can copy and paste the ISBNs, separating them with commas.</Dropdown.item>
                          </Dropdown.container>
                        </div>
                      </div>

                      <Controller
                        control={control}
                        name="title"
                        rules={{
                            onChange:changeIsbnInfo,
                            required:"Title is required"
                          }}
                        render={({ field: { onChange, onBlur, value, name, ref} }) => (
                          <div className="relative flex flex-col bg-white w-full">
                            <label className="text-sm leading-none mt-2 text-neutral-800 pb-2 font-bold">Title <span className="text-red-500">*</span></label>
                            <Select
                              placeholder={"Select Title"}
                              isMulti 
                              onMenuScrollToBottom={() => 
                                {
                                  setIsbnSkip(old => old += 1);
                                  dispatch(fetchIsbnList({limit : isbnLimit, skip : (isbnSkip + 1) * isbnLimit, selectedData : watch('isbn')?.length ? watch('isbn').map(isbn => isbn.label) : [], isbnTitle : isbnsString}))
                                }}
                              id="title-selector"
                              ref={ref}
                              onInputChange={(value) => {
                                dispatch(setIsbnString(value))
                                dispatch(fetchIsbnList({limit : isbnLimit, skip : 0, selectedData : watch('isbn')?.length ? watch('isbn').map(isbn => isbn.label) : [], isbnTitle : value}))
                              }}
                              name={name} 
                              onBlur={onBlur}
                              value={value}
                              components={{
                                MultiValueContainer:(props) => (
                                  <components.MultiValueContainer {...props}>
                                    <div className="flex w-full">
                                      {props.children}
                                    </div>
                                  </components.MultiValueContainer> 
                                ),
                                IndicatorsContainer:(props) => (
                                  <components.IndicatorsContainer {...props}>
                                    <div className="flex items-center gap-3 mr-2">
                                     {watch("title")?.length ? <div title="Clear All" onClick={() => {
                                        setValue("isbn",[])
                                        setValue("title",[])
                                      }} 
                                        className="flex items-center border-r-[1px] pr-4 border-solid border-gray-300">
                                        <MdCancel className="text-red-600 w-5 h-5 cursor-pointer"/>
                                      </div> : ""}
                                      <div className="flex items-center">
                                        <FaAngleDown className="text-gray-400 w-4 h-4 cursor-pointer"/>
                                      </div>
                                    </div>
                                  </components.IndicatorsContainer>
                                )
                              }}
                              styles={{
                                container:(provided) => ({
                                  // backgroundColor:"red",
                                  // maxWidth:"37vw",
                                  width:"100%",
                                  display:"flex"
                                }),
                                multiValue: (provided) => ({
                                  ...provided,
                                  height:"30px",
                                  display:"flex",
                                  alignItems:"center",
                                  borderRadius:"6px",
                                  minWidth:"none",
                                }),
                                valueContainer:(provided) =>({
                                  maxWidth:"28vw",
                                  width:"100%",
                                  paddingLeft:"8px",
                                  display:"flex",
                                  alignItems:"center",
                                  height:"40px",
                                  overflowX:"auto",
                                }),
                                control:(provided) => ({
                                  // ...provided,
                                  display:"flex",
                                  // maxWidth:"37vw",
                                  width:"100%",
                                  height:"40px",
                                  alignItems:"center",
                                  justifyContent:"space-between"
                                }),
                                // input: (provided) => ({
                                //   ...provided,
                                //   display:"flex",
                                //   alignItems:"center",
                                //   textAlign:"center",
                                //   justifyContent:"center",
                                //   marginRight:"60px",
                                //   fontSize: '16px',
                                // }),
                                // menuList:(provided) => ({
                                //   display:"none"
                                // })
                              }}
                              noOptionsMessage={() => 'Title not found'}
                              className="border border-solid border-neutral-200 font-poppins rounded-lg"
                              onChange={(selectedOptions) => onChange(selectedOptions)}
                              options={isbn_title_options} 
                            />
                            {/* <MultiSelectSearch options={isbn_title_options} name={name}/> */}
                                {/* <Tags placeholder="Select Title" values={isbn_title_options} isMultiple name={name}/> */}
                                {/* {errors.title && <span className="text-red-500 absolute bottom-[-25px]">{errors.title.message}</span>} */}
                          </div>
                        )}
                      />
                      {/* </div> */}
                  </div>
                </div>
              </section>

              <section className="mt-10">
              {/* <p className="font-bold text-[16px] m-0">Budget Requests Timelines</p> */}
                  <div className="h-[1px] bg-slate-400 mb-4" > </div>
                <div className="grid grid-cols-1 sm:grid-cols-3 w-full gap-3 items-center pt-2">
                                <Controller
                                  control={control}
                                  name="startDate"
                                  rules={{
                                    onChange:checkDate,
                                    required:"Start date is required"
                                  }}
                                  render={({ field }) => (
                                    <div className="relative flex w-full flex-col pt-3">
                                      <DatePicker
                                        required
                                        label="Start Date"
                                        // min={new Date()}
                                        placeholder="Choose Start Date"
                                        value={field.value}
                                        onChange={(date) => field.onChange(date)}
                                      />
                                        {errors.startDate && <span className="text-red-500 absolute bottom-[-25px]">{errors.startDate.message}</span>}
                                    </div>
                                  )}
                                />
                                <Controller
                                  control={control}
                                  name="endDate"
                                  rules={{
                                    // required:"End date is required"
                                    onChange:checkDate,
                                  }}
                                  render={({ field }) => (
                                    <div className="relative flex w-full flex-col pt-3" >
                                      <DatePicker
                                        disabled={!watch("startDate")}
                                        // required
                                        min={watch("startDate")}
                                        label="End Date"
                                        placeholder="Choose End Date"
                                        value={field.value}
                                        onChange={(date) => field.onChange(date)}
                                      />
                                        {errors.endDate && <span className="text-red-500 absolute bottom-[-25px]">{errors.endDate.message}</span>}
                                    </div>
                                  )}
                                />
                                <Controller
                                  control={control}
                                  name="deadline"
                                  rules={{
                                    required:"Deadline is required"
                                  }}
                                  render={({ field }) => (
                                    <div className="w-full pt-3 relative">
                                      <DatePicker
                                        required
                                        min={new Date()}
                                        label="Approval By (Deadline)"
                                        placeholder="Choose Deadline"
                                        value={field.value}
                                        onChange={(date) => field.onChange(date)}
                                      />
                                        {errors.deadline && <span className="text-red-500 absolute bottom-[-25px]">{errors.deadline.message}</span>}
                                    </div>
                                  )}
                                />
                </div>            
              </section>

            
              {/* <div className="flex flex-wrap font-poppins gap-3 items-start mt-3 max-sm:max-w-full">
              </div> */}
              <section className="my-10 ">
              {/* <p className="font-bold text-[16px] m-0">COOP Details</p> */}
              <div className="h-[1px] bg-slate-400 mb-4" > </div>
                <div className="grid grid-cols-1 sm:grid-cols-3 w-full gap-3 items-center pt-2">
                                    <Controller
                                      control={control}
                                      name="coopType"
                                      rules={{
                                        required:"COOP type is required",
                                        onChange:handleCoopCodeasChange
                                      }}
                                      render={({ field: { onChange, onBlur, value, name, ref} }) => (
                                        <div className="flex relative flex-col bg-white w-full">
                                          <label className="text-sm leading-none text-neutral-800 py-2 font-bold">COOP Type <span className="text-red-500">*</span></label>
                                          <Select
                                              placeholder={"Select COOP Type"}
                                              ref={ref}
                                              value={coopTypeObj}
                                              name={name} 
                                              onBlur={onBlur}
                                              noOptionsMessage={() => 'COOP type not found'}
                                              onChange={(selectedOptions) => onChange(selectedOptions)}
                                              options={coopTypes} 
                                              components={{
                                                ValueContainer:(props) => (
                                                  <components.ValueContainer {...props} className="text-sm">
                                                    {props.children}
                                                  </components.ValueContainer>
                                                )
                                              }}
                                            />
                                              {errors.coopType && <span className="text-red-500 absolute bottom-[-25px]">{errors.coopType.message}</span>}
                                        </div>
                                      )}
                                    />
                                        <Controller
                                          name="required_amount_budget"
                                          control={control}
                                          rules={{
                                            required:"Required Amount is mandatory",
                                            pattern:positiveNumberPattern,
                                            onChange:(event) =>{
                                              if(watch("required_amount_budget")){
                                                if(!watch("account_name") || !watch("account_number")){
                                                  setError("required_amount_budget",{message:"Please select an account"})
                                                  return
                                                }

                                                if(isNaN(Number(event.target.value))){
                                                  setError("required_amount_budget",{message:"Please Enter Number Only"})
                                                  return
                                                }

                                                if(Number(event.target.value) < 0){
                                                  setError("required_amount_budget",{message:"Please Enter Number greater than 0"})
                                                  return
                                                }

                                                dispatch(checkAccountBudget({amount : watch("required_amount_budget"), account_id:watch("account_number").value}))
                                              }
                                            }
                                          }}
                                          render={({ field  }) => (
                                            <div className="flex relative flex-col bg-white w-full">
                                              { amountBudgetInputShow 
                                              
                                                ?
                                                  (<div className="flex flex-col">
                                                    <label className="text-sm leading-none text-neutral-800 py-2 font-bold">Required Amount($) <span className="text-red-500">*</span></label>
                                                      <input
                                                        ref={amountBudgetRef}
                                                        onKeyDown={(event) => {
                                                          if(event.key === "Enter" || event.key ==="Tab"){
                                                            if(watch("required_amount_budget")){
                                                              if(isNaN(Number(watch("required_amount_budget")))){
                                                                setValue("required_amount_budget",null)
                                                              }
              
                                                              if(Number(watch("required_amount_budget")) < 0){
                                                                setValue("required_amount_budget",null)
                                                              }
                                                              setAmountBudgetInputShow(!amountBudgetInputShow)
              
                                                            }
                                                          }
                                                        }}
                                                        onBlur={() => {
                                                          if(watch("required_amount_budget")){
                                                            if(isNaN(Number(watch("required_amount_budget")))){
                                                              setValue("required_amount_budget",null)
                                                            }
            
                                                            if(Number(watch("required_amount_budget")) < 0){
                                                              setValue("required_amount_budget",null)
                                                            }
                                                            setAmountBudgetInputShow(!amountBudgetInputShow)
            
                                                          }
                                                        }}
                                                        type="text"
                                                        name={field.name}
                                                        maxLength={10}
                                                        value={field.value || undefined}
                                                        onChange={field.onChange}
                                                        placeholder="Amount"
                                                        className="p-2 border border-solid border-neutral-300  font-poppins rounded-sm "
                                                      />
                                                  </div>)
                                                :
                                                 ( <div className={`flex flex-col`} onClick={() => {
                                                    setAmountBudgetInputShow(!amountBudgetInputShow)
                                                  }}>
                                                    <label className="text-sm leading-none text-neutral-800 py-2 font-bold">Required Amount($) <span className="text-red-500">*</span></label>
                                                    <div className="p-2 border border-solid border-neutral-300  font-poppins rounded-[4px] h-[20px]">
                                                        {
                                                          isNaN(Number(watch("required_amount_budget"))) 
                                                            ? 
                                                          "" 
                                                            :
                                                          watch("required_amount_budget")
                                                            ? 
                                                          `${formatCurrency(Number(watch("required_amount_budget")))}`
                                                            :
                                                          <span className="text-gray-400">Amount</span>
                                                        }
                                                    </div>
                                                  </div>)
                                              }
                                              {errors.required_amount_budget && <span className="text-red-500 absolute mt-[70px] ">{errors.required_amount_budget.message}</span>}
                                            </div>
                                          )}
                                        />
                                    <Controller
                                      control={control}
                                      name="codeas"
                                      rules={{
                                        required:"Code as is required",
                                        onChange:handleCoopCodeasChange
                                      }}
                                      render={({ field: { onChange, onBlur, value, name, ref} }) => (
                                        <div className="flex relative flex-col bg-white w-full">
                                          <label className="text-sm leading-none text-neutral-800 py-2 font-bold">Code As <span className="text-red-500">*</span></label>
                                          <Select
                                            placeholder={"Select Code As"}
                                            ref={ref}
                                            value={codeasObj}
                                            name={name} 
                                            onBlur={onBlur}
                                            onChange={(selectedOptions) => onChange(selectedOptions)}
                                            options={codeAsOptions} 
                                            components={{
                                              ValueContainer:(props) => (
                                                <components.ValueContainer {...props} className="text-sm">
                                                  {props.children}
                                                </components.ValueContainer>
                                              )
                                            }}
                                          />
                                            {errors.codeas && <span className="text-red-500 absolute bottom-[-25px]">{errors.codeas.message}</span>}
                                        </div>
                                      )}
                                  />
                </div>
              </section>
                

              <div className="grid grid-cols-1 sm:grid-cols-2 mt-2 gap-3"> 
              <Controller
                  control={control}
                  name="description"
                  rules={{
                  
                  }}
                  render={({ field }) => (
                    <TextArea
                      label="Description"
                      placeholder="Description"
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="notes"
                  render={({ field }) => (
                    <TextArea
                      label="Notes"
                      placeholder="Notes"
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                    />
                  )}
                />
              </div>
            <section className="mt-10">
              {/* <p className="font-bold text-[16px] m-0">Approver Info</p> */}
              <div className="h-[1px] bg-slate-400  mb-4" > </div>
              
              <div className="mt-2">
                <div className="flex items-center mt-5 font-poppins">
                  <Controller
                      name="required_approval"
                      control={control}
                      rules={{
                        required:!profile?.role?.includes("approver") && "Please select an approver",
                        onChange:() => {
                          if(!watch("required_approval")) setValue('approver',[] as optionObj[])
                        }
                      }}
                      render={({ field }) => (
                        <div className="relative">
                          <input
                            type="checkbox"
                            className="mr-2 "
                            {...field}
                          />
                          {errors?.required_approval && <span className="text-red-500 flex whitespace-nowrap absolute bottom-[-25px]">{errors?.required_approval.message}</span>}
                        </div>
                      )}
                  />
                  <label className="font-bold">Required Approval</label>
                </div>

              {watch("required_approval") && <Controller
                  name="approver"
                  control={control}
                  rules={{
                    required:watch("required_approval") ? "Approver is required":false
                  }}
                  render={({ field: { onChange, onBlur, value, name, ref} }) => (
                    <div className="flex relative flex-col bg-white w-[50%]">
                      <label className="text-sm leading-none mt-2 text-neutral-800 py-2  font-bold">Select Approver <span className="text-red-500">*</span></label>
                      <Select 
                          placeholder={"Select Approver"}
                          ref={ref}
                          name={name} 
                          onBlur={onBlur}
                          noOptionsMessage={() => 'Approver not found'}
                          defaultValue={userApproverOptions.filter(user => {
                            if(profile?.role?.includes("approver")){
                              if(user.value === profile._id)
                              {  
                                return user;
                              }
                            }
                          })}
                          onChange={(selectedOptions) => onChange(selectedOptions)}
                          isMulti
                          options={userApproverOptions} 
                          styles={{
                            valueContainer:(base) =>({
                              ...base,
                              width:"100%",
                              // height:"70px",
                              overflowY:"auto",

                              
                            }),
                            menu: (base) => ({
                              ...base,
                              height:"80px",
                              overflowY:"auto",
                            }),
                            
                          
                            multiValue:(base) => ({
                              ...base,
                              
                            }),
                            multiValueLabel: (base) => ({
                              ...base,
                              padding:"6px"
                            }),
                          }}
                          components={{
                            ValueContainer:(props) => (
                              <components.ValueContainer {...props} className="text-sm ">
                                {props.children}
                              </components.ValueContainer>
                            )
                          }}
                        /> 
                      
                        {errors.approver && <span className="text-red-500 absolute bottom-[-25px]">{errors.approver.message}</span>}
                    </div>
                  )}
                />}
            
              </div>
            </section>

              <div className="flex items-center justify-end w-auto">
                <SubmitButton disabled={budgetLoading}/>
              </div>
            </form>
            <UnmatchedIsbnModal 
              showModal={unMatchedIsbnModal}
              setShowModal={setUnMatchedIsbnModal}
            />
          </main>
        </div>
      </section>
      <BudgetIsbnModal 
        showModal={isbnModal}
        setShowModal={setIsbnModal}
        data={watch("title") ?? []}
      />
    </div>
  );
};

export default BudgetRequest;
