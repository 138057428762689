import React, { useEffect } from 'react';
import InputField from './InputField';
import AmountField from './AmountField';
import InvoicePreview from './InvoicePreview';
import InvoiceReducer, {  getSelectedInvoice, InvoiceData, emptyMatch, emptySelectedInvoice } from '../../redux/reducers/InvoiceReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const InvoiceForm: React.FC = () => {
  // const invoices = useSelector((state: RootState) => state.invoiceReducer.data);
  const dispatch = useDispatch<AppDispatch>();
  const params= useParams();
  const invoices = useSelector((state:RootState)=>state.invoiceReducer.uploadedFile);

  useEffect(() => {
    if(params.id){
      dispatch(getSelectedInvoice({ _id: params.id }));
    }
  }, [params.id]);
 
  return (
    <div className='w-full flex'>
      <form className="flex flex-col rounded-none w-1/2 p-6 ">
        <section className="flex flex-col px-4 bg-white rounded-2xl max-md:px-5 ">
          <div className="flex z-10 flex-col mb-0 max-md:mb-2.5 max-md:max-w-full">
            <p className='font-bold'>Verify Invoices</p>
            <div className="flex flex-col   ">
              <div className="flex flex-col ">
                <div className="flex gap-6 items-start w-full text-[14px] leading-none whitespace-nowrap  mt-6">
                  <InputField 
                    label={"Invoice Id"} 
                    value={invoices.InvoiceId}
                    inputClassName='overflow-y-auto'
                    className='overflow-hidden'
                  />
                </div>
                <div className="flex gap-6 items-start w-full text-[14px] leading-none whitespace-nowrap  mt-6">
                  <InputField 
                    label={"Invoice Number"} 
                    value={invoices.Invoice_number}
                    inputClassName='overflow-y-auto'
                    className='overflow-hidden'
                  />
                </div>
                <div className="flex gap-6 items-start w-full text-base leading-none whitespace-nowrap max-md:max-w-full mt-6">
                  <InputField 
                    label={"Invoice Date"} 
                    value={moment(invoices.Invoice_Date).format("MM DD YYYY")}
                    inputClassName='overflow-y-auto'
                    className='overflow-hidden'
                  />
                </div>
                <div className="flex gap-6 items-start text-[14px]  text-black mt-6 mb-6 w-full whitespace-nowrap max-md:max-w-full">
                  <AmountField 
                    amount= {`$ ${invoices.Invoice_amount}`}
                    inputClassName='overflow-y-auto'
                    className='overflow-hidden'
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
      <div className='py-6'>
      <InvoicePreview />
      </div>
    </div>
  );
};

export default InvoiceForm;

