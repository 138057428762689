// import React from 'react';

// interface TextAreaProps {
//   label: string;
//   defaultValue: string;
// }

// const TextArea: React.FC<TextAreaProps> = ({ label, defaultValue }) => {
//   return (
//     <div className="flex flex-col mt-3 w-full text-sm max-md:max-w-full">
//       <label className="self-start leading-none text-neutral-800">{label}</label>
//       <div className="flex relative flex-col mt-2 w-full leading-6  text-zinc-950 max-md:max-w-full">
//         <textarea
//           className="flex z-0 flex-1 w-[26vw] bg-white rounded-md border 
//           border-solid border-neutral-200  max-md:max-w-full p-3"
//           defaultValue={defaultValue}
//         />
//       </div>
//     </div>
//   );
// };

// export default TextArea;
import React from 'react';

interface TextAreaProps {
  label: string;
  value: string; // Use value instead of defaultValue
  placeholder:string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void; // Handle changes
  onBlur: (event: React.FocusEvent<HTMLTextAreaElement>) => void; // Handle blur events
}

const TextArea: React.FC<TextAreaProps> = ({ label, value, onChange, onBlur,placeholder }) => {
  return (
    <div className="flex flex-col mt-3 w-full text-sm max-md:max-w-full">
      <label className="self-start leading-none text-neutral-800 font-bold">{label}</label>
      <div className="flex relative flex-col mt-2 w-full leading-6  text-zinc-950 max-md:max-w-full">
        <textarea
          className="flex z-0 flex-1  bg-white rounded-md border resize-none
          border-solid border-neutral-200  max-md:max-w-full p-3  font-poppins"
          value={value} 
          placeholder={placeholder}
          onChange={onChange} 
          onBlur={onBlur} 
        />
      </div>
    </div>
  );
};

export default TextArea;
