import React from 'react';

type StatCardProps = {
  src: string;
  alt: string;
  title: string;
  stat: any;
  formatNumber?:any;
};

const StatCard: React.FC<StatCardProps> = ({ src, alt, title, stat,formatNumber }) => {
  return (
    <div className="flex flex-col grow   overflow-hidden  " title={stat}  >
      <div className="relative flex  py-6  bg-white rounded-2xl h-[100px]">
        <div className=''>
          <div className="flex lg:text-lg md:text-sm font-semibold">
            <img loading="lazy" src={src} alt={alt} className=" mx-3 lg:w-6 lg:h-6 md:w-6 md:h-6 " />
            <div className="max-w-[200px]">{title}</div>
          </div>
          <div title={stat} className=" lg:mt-7 md:mt-3 lg:ml-3.5 md:ml-4 text-[18px] text-center font-semibold">
            {/* {formatNumber(stat)} */}
            {stat}
          </div>
        </div>
        <div className='absolute text-gray-400 lg:text-sm md:text-xs lg:top-5 lg:right-5 md:top-3 md:right-3'></div>
      </div>
    </div>
  );
};

export default StatCard;