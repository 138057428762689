import React from "react";

interface TextAreaProps {
  label: string;
  value: string|number;
}

const TextArea: React.FC<TextAreaProps> = ({ label, value }) => {
  return (
    <div className="flex flex-col mt-6 w-full text-base max-md:max-w-full">
      <label
        htmlFor={label.toLowerCase().replace(/\s/g, "-")}
        className="self-start font-semibold leading-none text-neutral-800 text-[14px] "
      >
        {label}
      </label>
      <div className="flex relative flex-col mt-3 w-full leading-8 min-h-[80px] text-neutral-500 max-md:max-w-full">
        <div className="flex z-0 flex-1 w-full rounded-md border border-solid bg-zinc-100 border-neutral-200 min-h-[80px]" />
        <div className="absolute gap-10 top-[15px] w-[635px] px-3.5 max-md:max-w-full">
          {value}
        </div>
      </div>
    </div>
  );
};

export default TextArea;
