import React from 'react';

interface InputFieldProps {
  id?:string|number;
  label: string;
  value?: string | number ;
  className?: string;
  inputClassName?:string;
  onClick?: () => void;
}

const InputField: React.FC<InputFieldProps> = ({ label, value, className = '' ,inputClassName, onClick }) => {
  return (
    <div className={`flex text-[14px] flex-col flex-1 shrink ${className}`} onClick={() => {
      if (onClick) onClick(); 
    }}>
      <label className=" font-semibold leading-none text-neutral-800">
        {label}
      </label>
      <div className={`flex items-center mt-2   text-neutral-500 ${inputClassName}  rounded-md border border-solid bg-zinc-100 border-neutral-200 
     `}>
        <div className=" mx-2 my-4  text-[14px]">
          {value}
        </div>
      </div>
    </div>
  );
};

export default InputField;