export const dashboardHeaders = {
    Account_name : 'Account Name',
    Account_number : 'Account Number',
    Budget: 'Budget',
    Remaining:"Remaining",
    incurred:"Spent",
    
}

export const ActionScreenHeader = {
    // _id:"id",
    // account_name:"Account Name",
    // account_number:"Account Number",
    claim_number:"Claim Number",
    isbn:"ISBN",
    title:"Title",
    description:"Description",
    notes:"Notes",
    dollar_amount:"Amount ($)",
    approved_by:"Approved By",
    // time_warner_ar:"nn",
    code_as:"Code As",
    // credit_number:"Credit number",
    // credit_date:"Credit Date",
    // promotion_details:"Promotion Details",
    // event_date:"Event Date",
    // createdAt: "Created AT",
    // updatedAt: "Updated at",
    // __v: "v",
  }; 
export const InvoicesHeaders = {
    // _id:"Id",
    Invoice_number:"Invoice Number",
    updatedAt:"Updated On",
    Status:"Status",
//     Invoice_amount:"Amount",
// Invoice_URL:"url"

    // Invoice_amount:"Invoice Amount",
    // createdAt:"Created At",
    // updateAt:'Update At'
    // Action:"Action"
};


export const budgetRequestsHeaders = {
    account_name:"Account Name",
    isbn:'ISBN',
    title:'Title',
    deadline:'Deadline',
    status:'Status',
    required_amount_budget:'Amount',
  
}

export const ISBNTableHeaders = {
    isbn : 'ISBN',
    title : 'Title'
}

export const dashboardData = [
    {
        index : 1,
        account : "Ritik ",
        allocated_budget : 987654321234,
        month : "July 2024",    
    },
    {
        index : 2,
        account : "Ritik ",
        allocated_budget : 987654321234,
        month : "July 2024",    
    },
    {
        index : 3,
        account : "Ritik ",
        allocated_budget : 987654321234,
        month : "July 2024",    
    },
    {
        index : 4,
        account : "Ritik ",
        allocated_budget : 987654321234,
        month : "July 2024",    
    },
    {
        index : 4,
        account : "Ritik ",
        allocated_budget : 987654321234,
        month : "July 2024",    
    },
    {
        index : 4,
        account : "Ritik ",
        allocated_budget : 987654321234,
        month : "July 2024",    
    },
    {
        index : 4,
        account : "Ritik ",
        allocated_budget : 987654321234,
        month : "July 2024",    
    },
    {
        index : 4,
        account : "Ritik ",
        allocated_budget : 987654321234,
        month : "July 2024",    
    },
    {
        index : 4,
        account : "Ritik ",
        allocated_budget : 987654321234,
        month : "July 2024",    
    },
    {
        index : 4,
        account : "Ritik ",
        allocated_budget : 987654321234,
        month : "July 2024",    
    },
]

export const BudgetRequestsData = [
 {
    isbn:'1234567890',
    title:'titlellll1',
    deadline:'12/12/2023',
    status:'Approved',
    amount:'$ 9,099',
    statusCode:1
 },
 {
    isbn:'1234567897',
    title:'titlellll2',
    deadline:'12/12/2023',
    status:'Declined',
    amount:'$ 9,099',
    statusCode:3
 },
 {
    isbn:'1234567890',
    title:'titlellll3',
    deadline:'12/12/2023',
    status:'Approved',
    amount:'$ 9,099',
    statusCode:1
 },
 {
    isbn:'1234567890',
    title:'titlellll4',
    deadline:'12/12/2023',
    status:'Approved',
    amount:'$ 9,099',
    statusCode:1
 },
 {
    isbn:'1234567890',
    title:'titlellll',
    deadline:'12/12/2023',
    status:'Waiting For Approval',
    amount:'$ 9,099',
    statusCode:2
 },
 {
    isbn:'1234567890',
    title:'titlellll',
    deadline:'12/12/2023',
    status:'Approved',
    amount:'$ 9,099',
    statusCode:1
 },
 {
    isbn:'1234567890',
    title:'titlellll',
    deadline:'12/12/2023',
    status:'Waiting For Approval',
    amount:'$ 9,099',
    statusCode:2
 },
 {
    isbn:'1234567890',
    title:'titlellll',
    deadline:'12/12/2023',
    status:'Declined',
    amount:'$ 9,099',
    statusCode:3
 },   
]


export const invoicedata = [
    {
      id: "#1234",
      Date: "12.12.2023",
      
    },
    {
      id: "#1234",
      Date: "12.12.2023",
     
    },
    {
      id: "#1234",
      Date: "12.12.2023",
    //   Action:  <FaArrowDown />
    },
    {
      id: "#1234",
      Date: "12.12.2023",
    //   Action:  <FaArrowDown />
    },
    {
      id: "#1234",
      Date: "12.12.2023",
    //   Action:  <FaArrowDown />
    },
    {
      id: "#1234",
      Date: "12.12.2023",
    //   Action:  <FaArrowDown />
    },
  ];
  export const actionScreendata=[
    {
        Account_name:"nn",
        Account_number:"7878",
        isbn:"isbn"
    },
    {
        Account_name:"nn",
        Account_number:"7878",
        isbn:"isbn"
    },
    {
        Account_name:"nn",
        Account_number:"7878",
        isbn:"isbn"
    },
    {
        Account_name:"nn",
        Account_number:"7878",
        isbn:"isbn"
    },
    {
        Account_name:"nn",
        Account_number:"7878",
        isbn:"isbn"
    },
   
  ]