import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { status } from '../../redux/reducers/budgetreqReducer';

interface ApprovalStatusProps {
  statusOfForm: string;
  
}
const ApprovalStatus: React.FC<ApprovalStatusProps> = ({ statusOfForm }) => {
  const currentStatus=useSelector((state:RootState)=>status(state))
  return (
    <div className={`m-3 p-3 self-stretch  text-center font-medium leading-none 
   whitespace-nowrap rounded-md 
     bg-opacity-10 max-md:max-w-full text-base ${currentStatus== 1 ?
       'bg-green-600 text-green-900 ': currentStatus==2?' bg-red-700 text-red-700' :"m-0"}
     `}>
      {statusOfForm}
    </div>
  );
};

export default ApprovalStatus;