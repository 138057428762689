import {configureStore} from '@reduxjs/toolkit';
import profileReducer from './reducers/profileReducer';
import UserReducer from './reducers/UserReducer'
import onBoardingReducer from './reducers/onBoardingReducer';
import roleReducer from './reducers/roleReducer';
import dashBoardReducer from './reducers/dashboardReducer';
import forgotPasswordReducer from "./reducers/forgot-passwordReducer";
import budgetReducer from "./reducers/budgetreqReducer";
import ApprovedDeclined from './reducers/ApprovedDeclined';
import TimeReducer from './reducers/timelineReducer';
import amountReducer from './reducers/AccountDetailReducer'
import dropdownReducer from './reducers/dropdownReducer';
import invoiceReducer from './reducers/InvoiceReducer';
import notificationReducer from "./reducers/nootificationReducer";

export const store = configureStore({
    reducer:{
        profileReducer,
        UserReducer,
        onBoardingReducer,
        roleReducer,
        dashBoardReducer,
        forgotPasswordReducer,
        budgetReducer,
        ApprovedDeclined,
        TimeReducer,
        amountReducer,
        dropdownReducer,
        invoiceReducer,
        notificationReducer
    },
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({
        serializableCheck: false,
      }),
})

export type AppStore = typeof store; // type of our store variable

// Infer the RootState and App type from the store itself
export type RootState = ReturnType<AppStore['getState']>

export type AppDispatch = AppStore['dispatch']