export const budgetRequestsTabs = [
    {
        tabName:"Recent Requests",
        statusCode:100,
    },
    {
        tabName:"Approved Requests",
        statusCode:1,
    },
    {
        tabName:"Pending Requests",
        statusCode:0,
    },
    {
        tabName:"Declined Requests",
        statusCode:2,
    }
]