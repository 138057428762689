import { FaAccusoft, FaArrowDown } from "react-icons/fa";

export const dashboardHeaders = {
  index: "ID",
  account: "Account",
  allocated_budget: "Allocated Budget",
  month: "Month",
};
export const InvoicesHeaders = {
  id: "ID",
  Title:"Title",
  Date: "Date",
  Action: "Action",
};
export const Userheader={
  firstName:"First Name",
  lastName:"Last Name",
  email:"Email",
  role:"Role",
  isActive:"Status"
}

export const dashboardData = [
  {
    index: 1,
    account: "Ritik ",
    allocated_budget: 987654321234,
    month: "July 2024",
  },
  {
    index: 2,
    account: "Ritik ",
    allocated_budget: 987654321234,
    month: "July 2024",
  },
  {
    index: 3,
    account: "Ritik ",
    allocated_budget: 987654321234,
    month: "July 2024",
  },
  {
    index: 4,
    account: "Ritik ",
    allocated_budget: 987654321234,
    month: "July 2024",
  },
  {
    index: 4,
    account: "Ritik ",
    allocated_budget: 987654321234,
    month: "July 2024",
  },
  {
    index: 4,
    account: "Ritik ",
    allocated_budget: 987654321234,
    month: "July 2024",
  },
  {
    index: 4,
    account: "Ritik ",
    allocated_budget: 987654321234,
    month: "July 2024",
  },
  {
    index: 4,
    account: "Ritik ",
    allocated_budget: 987654321234,
    month: "July 2024",
  },
  {
    index: 4,
    account: "Ritik ",
    allocated_budget: 987654321234,
    month: "July 2024",
  },
  {
    index: 4,
    account: "Ritik ",
    allocated_budget: 987654321234,
    month: "July 2024",
  },
];

export const invoicedata = [
  {
    id: "#1234",
    Title: "May your life be Delicisoa",
    Date: "12.12.2023",
    // Action:   <FaArrowDown />
  },
  {
    id: "#1234",
    Title: "Vegetable in holiday",
    Date: "12.12.2023",
    // Action:  <FaArrowDown />
  },
  {
    id: "#1234",
    Title: "Feast your eyes on food",
    Date: "12.12.2023",
    // Action:  <FaArrowDown />
  },
  {
    id: "#1234",
    Title: "Birds",
    Date: "12.12.2023",
    // Action:  <FaArrowDown />
  },
  {
    id: "#1234",
    Title: "The eternal ",
    Date: "12.12.2023",
    // Action:  <FaArrowDown />
  },
  {
    id: "#1234",
    Title: "Wayward",
    Date: "12.12.2023",
    // Action:  <FaArrowDown />
  },
];

export const Userdata=[
  {
    firstname:"manjeet",
    lastname:"kaur",
    email:"simmu@gmail.com",

  },
  {
    firstname:"manjeet",
    lastname:"kaur",
    email:"simmu@gmail.com",
  
  },
  {
    firstname:"manjeet",
    lastname:"kaur",
    email:"simmu@gmail.com",
  
  },
]