
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { passwordPattern } from '../../../constants/validationPatterns';
import { useDispatch, useSelector } from 'react-redux';
import { setPassword, onBoardingData, checkOnboarding, emptyOnboardingObj } from '../../../redux/reducers/onBoardingReducer';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../../redux/store';
import { toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

interface IFormInput {
  password: string;
  confirmPassword: string;
}

const MyForm: React.FC = () => {
  const [eyeState,setEyeState] = useState(false)
  const [eyeState2,setEyeState2] = useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate();
  const location = useLocation()
  const Onboarding = useSelector(onBoardingData)

  useEffect(() => {
    //onboarding check if token is valid

    if(Onboarding.onboarding){
      if(Onboarding.onboarding.status === 403){
        navigate("/login");
        toast.success(Onboarding.onboarding.message)
        dispatch(emptyOnboardingObj())
        return
      }else if(Onboarding.onboarding.status === 401){
        navigate("/login");
        toast.success(Onboarding.onboarding.message)
        dispatch(emptyOnboardingObj())
        return
      }
    }

    // if password is setted navigate to login page
    
    if(Onboarding.setPass)
      if(Onboarding.setPass.status == 200){
        navigate("/login")
        toast.success(Onboarding.setPass.message)
        dispatch(emptyOnboardingObj())
        return
      }
  },[Onboarding])

  useEffect(() => {
    const token = location.pathname.split("/")[location.pathname.split("/").length - 1];
    dispatch(checkOnboarding({token : token}))
  },[])

  const { register, handleSubmit, formState: { errors }, watch ,setError} = useForm<IFormInput>();

  const checkPassword = () =>{
    if(watch('password') !== watch("confirmPassword"))
      setError('confirmPassword', { message: 'password did not match !' });
    else if(watch("password")===watch("confirmPassword")){
      setError("confirmPassword",{message:""})
    }
  }

  const onBoarding = (data : any) => {
    data['token'] = location.pathname.split("/")[location.pathname.split("/").length - 1]
    dispatch(setPassword(data))
  }

  return (
    <div className="flex flex-col mt-2 ">
         <div className="text-black flex flex-col mb-4 text-center">
       <span className="font-bold text-[40px] ">
      Set Your password
        </span>
        
      </div>
      <form onSubmit={handleSubmit(onBoarding)} className="flex flex-col text-left space-y-4">

        <div  className="flex relative flex-col text-base rounded-md text-zinc-400 ">
          <label  className="block text-sm  font-medium text-gray-700"></label>
          <input
            id="password"
            type={eyeState ? "text":"password"}
            placeholder='Password'
            {...register('password', { required: 'Required', pattern:passwordPattern })}
            className="py-3 px-4 text-sm font-poppins rounded-md  bg-zinc-100"
          />
            {watch("password")?.length ? 
              <div className="absolute flex top-2 right-3"  onClick={() => setEyeState(!eyeState)}> 
              {eyeState ? 
                    (<div className="text-gray-800 dark:text-white border rounded
                      ">
                      <span className="cursor-pointer px-2  " ><FaEye/> </span>
                    </div>)
                    : 
                    (<div className="text-gray-800 dark:text-white border rounded
                      ">
                      <span className="cursor-pointer px-2  " ><FaEyeSlash/> </span>
                    </div>   )   
              }
              </div>
              :""
              } 
          {errors?.password && <span className='text-red-500 text-sm flex flex-wrap  max-w-[300px] '>{errors?.password.message}</span>}
        </div>
        <div  className="flex relative flex-col text-base rounded-md text-zinc-400">
          <label  className="block text-sm font-medium text-gray-700"></label>
          <input
            id="confirmPassword"
            type={eyeState2 ? "text":"password"}
            placeholder='Confirm-password'
            {...register('confirmPassword', { required: ' Required', onChange:({target}) => checkPassword() })}
            className="py-3 px-4 text-sm font-poppins rounded-md  bg-zinc-100"
          />
            {watch("confirmPassword")?.length ? 
              <div className="absolute flex top-2 right-3"  onClick={() => setEyeState2(!eyeState2)}> 
              {eyeState2 ? 
                    (<div className="text-gray-800 dark:text-white border rounded
                      ">
                      <span className="cursor-pointer px-2  " ><FaEye/> </span>
                    </div>)
                    : 
                    (<div className="text-gray-800 dark:text-white border rounded
                      ">
                      <span className="cursor-pointer px-2  " ><FaEyeSlash/> </span>
                    </div>   )   
              }
              </div>
              :""
              } 
          {errors?.confirmPassword && <span className='text-red-500 text-sm flex flex-wrap  max-w-[300px] '>{errors?.confirmPassword.message}</span>}
        </div>
        <div className=' text-sm font-poppins rounded-md w-full '>
        <button
          type="submit"
          className="flex items-center cursor-pointer justify-center font-bold text-sm md:text-base  text-white rounded-md w-full bg-red-600 border border-red-600 py-3 px-4"
        >
          Submit
        </button>
        </div>
      </form>
    </div>
  );
};

export default MyForm;

