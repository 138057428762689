import React from 'react';

interface AmountFieldProps {
  amount:  number | any;
  inputClassName?:string;
  className?:string;
}

const AmountField: React.FC<AmountFieldProps> = ({ amount ,inputClassName,className}) => {
  return (
    <div className={`flex flex-col ${className} flex-1 shrink w-full basis-0  `}>
      <label htmlFor="amount" className="self-start font-semibold leading-none text-neutral-800">
        Amount
      </label>
      <div className={`flex rounded-md border border-solid ${inputClassName} bg-zinc-100 border-neutral-200 relative flex-col mt-2 text-black font-bold leading-none max-md:max-w-full`}>

        <div className=" mx-2 my-4  bottom-[18px]">
          {amount}
        </div>
      </div>
    </div>
  );
};

export default AmountField;