import React, { useState } from 'react';
import UserDetailsDialog from './UserManage'


const UserDetails: React.FC = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleSave = (data: { firstName: string;
    lastName: string;
    email: string;
    role: string;
 }) => {
    console.log('User Details Saved:', data);
    handleCloseDialog();
  };

  return (
    <div className="p-4">
      <UserDetailsDialog
        open={handleOpenDialog}
        openDialog={openDialog}
        onClose={handleCloseDialog}
        onSave={handleSave}
      />
    </div>
  );
};

export default UserDetails;
