import { createRoot } from "react-dom/client";
import App from "./App";
import "./assets/css/global.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

