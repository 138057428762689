import React from 'react';
import bgLogin from '../assets/images/Bg-login.png'
// h-[641px] 

const HeroSection: React.FC = () => {
  return (
    <section className="relative  h-screen flex-col w-1/2 hidden lg:flex  ">
      <div className="flex  relative flex-col grow text-6xl text-center text-white leading-[54px] overflow-hidden max-md:mt-10 max-md:max-w-full max-md:text-4xl max-md:leading-10">
        <img 
          src= {bgLogin}
          alt="Background image for hero section" 
          className="object-cover inset-0  absolute size-full" 
        />
        <div className="relative h-[100vh] text-[50px] flex items-center
         justify-center bg-red-600 bg-opacity-80 
          max-md:px-5 max-md:pt-24 max-md:pb-28 max-md:max-w-full
           max-md:text-4xl max-md:leading-10  ">
          Your Gateway to  Seamless   Management
      
        </div>
      </div>
    </section>
  );
};

export default HeroSection;