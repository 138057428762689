import React, {useEffect, useState} from "react";
import EmailInput from "../../common-components/emailInput";
import PasswordInput from "../../common-components/passwordInput";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { emailPattern, passwordPattern } from "../../constants/validationPatterns";
import { emptyUserObj, loginUser, userProfile } from "../../redux/reducers/profileReducer";
import { fetchUserInfo } from "../../redux/reducers/UserReducer";
import { AppDispatch } from "../../redux/store";
import axios from "axios";
import { toast } from "react-toastify";
import { emptyOnboardingObj, onBoardingData } from "../../redux/reducers/onBoardingReducer";

interface formValues {
  email: string,
  password: string,
}

const LoginForm: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const Onboarding = useSelector(onBoardingData)
  const navigate = useNavigate()
  const userData = useSelector(userProfile);

  useEffect(() =>{
    if(userData.statusCode === 200) {
      axios.defaults.headers['authentication'] = userData?.token ? userData.token : '';
      toast.dismiss()
      toast.success(userData.message)
      navigate("/");
      dispatch(fetchUserInfo());
    }else if(userData.statusCode === 404 || userData.statusCode === 401){
      dispatch(emptyUserObj())
      toast.error(userData.message)
      dispatch(emptyUserObj())
    }
    if(Onboarding.onboarding)
      if(Onboarding.onboarding.status === 403){
        toast.dismiss()
        toast.error(Onboarding.onboarding.message);  
        dispatch(emptyOnboardingObj())
    }
    if(userData.statusCode === 404){
        toast.dismiss()  
        toast.error(userData.message);  
        dispatch(emptyUserObj())
    }
  },[userData])

  const {
      control,
      handleSubmit,
      formState:{errors}
    } = useForm<formValues>()

  return (
    <form onSubmit={handleSubmit((data) => dispatch(loginUser(data)))} 
    className="flex flex-col w-full max-w-md mx-auto md:max-w-lg ">
      <Controller 
        name="email"
        control={control}
        rules={{
          required:'Email is required',
          pattern:emailPattern
        }}
        render={({field}) => (
          <EmailInput id="email" {...field} error={errors?.email}/>
        )}
      />
      <Controller 
        control={control}
        name="password"
        rules={{
          required:'Password is required', 
        }}
        render={({field}) => (
          <PasswordInput id="password" {...field} error={errors?.password}/>
        )}
      />
      <Link
        to="/forgot-password"
        className="text-sm
         text-blue-500 text-right hover:text-blue-700 transition-colors duration-300  mt-3 cursor-pointer"
      >
        Forgot Password?
      </Link>

      <button
        type="submit"
        className="flex items-center justify-center 
        font-bold  text-[17px] text-white rounded-md w-full
         max-w-lg cursor-pointer bg-red-600 border border-red-600 py-3 px-4 mt-4 transition-colors duration-300 hover:bg-red-700"
      >
        Login to account
      </button>

    </form>
  );
};

export default LoginForm;

