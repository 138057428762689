import React from 'react';
import Logo from '../forgotPassword/Logo'
import ResetPasswordForm from './ResetPasswordForm';
import Hero from '../../../common-components/HeroSection';

const  ResetPasswordPage: React.FC = () => {
  return (
    <main className="flex flex-col  items-center  bg-white">
      <div className="flex flex-col lg:flex-row w-full  ">
        <section className="flex flex-col items-center lg:w-1/2  mt-20">
          <div className="flex flex-col items-center justify-center ">
            <Logo />
            <ResetPasswordForm />
          </div>
        </section>    
          <Hero />
      </div>
    </main>
  );
};

export default ResetPasswordPage;