export const emailPattern = {
    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    message: 'Enter a valid email address',
}

export const passwordPattern = {
    value:/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{11,}$/,
    message:'Password must be at least 10 characters long  and include a combination of letters, numbers, and special characters. .'
}

export const positiveNumberPattern = {
    value: /^(?!0(\.0*)?$)[+]?\d*\.?\d+$/,  // Pattern for positive numbers including decimals
    message: 'Please enter number greater than 0.'  // Validation error message
  };