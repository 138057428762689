import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { emptyUnMatchedIsbns, ISBNData } from '../../redux/reducers/budgetreqReducer';
import { AppDispatch } from '../../redux/store';

interface modalObj {
    showModal : boolean;
    setShowModal : (show: boolean) => void;
    
}

const UnmatchedIsbnModal: React.FC<modalObj> = ({showModal, setShowModal}) => {
  
    const isbns = useSelector(ISBNData);
    const dispatch = useDispatch<AppDispatch>()
    const handleClose = () => {
        setShowModal(false)
        dispatch(emptyUnMatchedIsbns())
    }

    return (
    <Dialog open={showModal} onClose={handleClose} maxWidth="xs" fullWidth>
    <DialogTitle className="font-poppins bg-black text-white custom-color" >UNMATCHED ISBNS </DialogTitle>
    <DialogContent>
      <div className="font-poppins bg-white">
        <div className="mb-4 text-[14px]">
          {
              isbns?.unMatchedIsbns.map(isbn => (
                  <ul>
                      <li>{isbn}</li>
                  </ul>
              ))
          }
        </div>
      </div>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} className=" cursor-pointer flex items-center mt-5 justify-center w-full font-bold text-[15px] text-white rounded-md  bg-red-600 text-center border-red-600 border">
        <span className="py-3 w-full font-poppins rounded text-white  bg-red-600 flex justify-center items-center">
          Close
        </span>
      </Button>
    </DialogActions>
</Dialog> 
  )
}

export default UnmatchedIsbnModal