import React, { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type DashboardItemProps = {
  svg: ReactNode;
  alt: string;
  label: string;
  path: string;
};

const DashboardItem: React.FC<DashboardItemProps> = ({ svg, label, path }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  
  return (
    <div
      onClick={() => path !== "/comminSoon" && navigate(path)}
      className={`flex ${pathname === path ? 'bg-rose-100 text-red-600 font-bold' : 'bg-white text-gray-500'} hover:bg-rose-100 cursor-pointer overflow-hidden text-sm gap-3 items-center p-2.5 mt-1 whitespace-nowrap rounded-lg min-h-[28px] `}
    >
      {svg}
      <div className={` md:hidden lg:block ${pathname === path ? 'text-red-500 font-bold text-[15px]' : ' text-gray-400 text-[15px]'}`}>
        {label}
      </div>
    </div>
  );
};

export default DashboardItem;
