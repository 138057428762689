import React, { ReactNode } from "react";

interface NewReqProps {
  title?: string;
  element?: ReactNode;
  className?: string
}

const NewReq: React.FC<NewReqProps> = ({ title ,element, className}) => {
  return (
    <div className={`${className} w-full`}>
      <div className=" flex-col w-full  text-zinc-950 ">
        <div className="flex w-full justify-between">
            <h2 className="flex flex-col gap-2.5 self-stretch font-semibold text-lg leading-8 whitespace-nowrap">
              {title}
            </h2>
          <div className="flex gap-3 justify-between items-center ">
            {/* <button
              className="flex gap-2 cursor-pointer justify-center items-center self-stretch px-4 py-2.5 my-auto bg-white rounded-lg min-h-[40px] w-[52px]"
            >
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/2ae713d63bb54b2d870e45d34372bad5875a43ecb7811abfac0fb61c6b90899e?placeholderIfAbsent=true&apiKey=a88c36c4e69e48eb98243259c870fe00"
                alt="Icon"
                className="object-contain self-stretch my-auto w-5  aspect-square"
              />
            </button> */}
              {element}
            </div>
        </div>
      </div>
    </div>
  );
};

export default NewReq;
