import React from 'react';
import Logo from './Logo';
import ResetPasswordForm from './OnBoardingform'
import Hero from '../../../common-components/HeroSection';

const OnBoardingPage: React.FC = () => {
  return (
    <main className="flex flex-col  items-center  bg-white ">
      <div className="flex flex-col lg:flex-row w-full  ">
        <section className="flex flex-col items-center lg:w-1/2 mt-20">
        <div className="flex flex-col justify-center items-center ">
            <Logo />
            <ResetPasswordForm />
        </div>
        </section>    
          <Hero />
      </div>
    </main>
  );
};

export default OnBoardingPage;