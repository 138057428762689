import React from 'react';
import Alogo from '../../../assets/images/logo.png'

const Logo: React.FC = () => {
  return (
    <img 
       src={Alogo} 
      alt="Company Logo" 
      className="object-contain self-center w-40 max-w-full aspect-[1.44]  " 
    />
  );
};

export default Logo;