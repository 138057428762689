import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface roleState {
  time?:number;
  selectedTime?:number;
}

const initialState: roleState = {
  time:0,
  selectedTime:0
};

export const timeSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateTime : (state, action)  => {
        state.time = action.payload;
    },
    createIntervalTime : (state, action) => {
        state.selectedTime = action.payload;
    },
    minusPerSecondSelectedTime : (state) => {
        if(state.selectedTime)
            state.selectedTime = state.selectedTime - 1
    }
  },
});

export const {updateTime, createIntervalTime, minusPerSecondSelectedTime} = timeSlice.actions

export const time = (state: RootState) => state.TimeReducer.time;
export const selectedTime = (state: RootState) => state.TimeReducer.selectedTime;

export default timeSlice.reducer;
