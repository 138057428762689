import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { API } from '../../constants/API';
import { RootState } from '../store';
import { act } from 'react';

interface InvoiceData{
  data:InvoiceItem[],
  totalDocuments:number;
  totalPages:number;
}

interface InvoiceItem {
  id: string;
  Invoice_number:number;
  Invoice_amount:number;
  InvoiceId:number;
  Invoice_Date?:"";
  createdAt?:"";
  updateAt?:"";
  Invoice_URL:string;
  Invoice_status:number;
  status?:number;
  message?:string;
}

interface DataState {
  data: InvoiceItem[];
  isLoading: boolean;
  isError: boolean;
  status: string | number;
  statusCode: number;
  message: string | null;
  isUploadLoading?: boolean; 
  uploadedFile: InvoiceItem;
  matchFile:{
    status:number;
    message:string;
  };
  totalDocuments:number;
  totalPages:number;
}

const initialState: DataState = {
  data: [],
  isLoading: false,
  isError: false,
  status: '',
  statusCode: 0,
  message: null,
  isUploadLoading: false, 
  uploadedFile: {
    id: "",
    Invoice_number:0,
    Invoice_amount:0,
    Invoice_URL:"",
    Invoice_status:0,
    InvoiceId:0,
    status:0,
    message:""
  },
  matchFile:{
    status:0,
    message:""
  },
  totalDocuments:0,
  totalPages:0,
};

export const fetchInvoices = createAsyncThunk(
  'fetchInvoices',
  async (data:{page: number,limit:number} , { rejectWithValue }) => {
    try {
      const response: AxiosResponse<InvoiceData> = await axios.post(API.GETINVOICES,data);
      return response;
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue('error occurred');
    }
  }
);
export const getSelectedInvoice= createAsyncThunk(
  'getSelectedInvoice',
  async (data:any, { rejectWithValue }) => {
    try {
      const response= await axios.get(`${API.GETSELECTEDINVOICE}/${data._id}` );
      return response;
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue('error occurred');
    }
  }
);


export const postUploadInvoices = createAsyncThunk(
  "postUploadInvoice",
  async (data:File|any, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('invoice', data);
      const response = await axios.post(API.POSTINVOICES, formData);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);
export const PostmatchInvoices = createAsyncThunk(
  "PostmatchInvoices",
  async (data:any, { rejectWithValue }) => {
    try {
      // const formData = new FormData();
      // formData.append('invoice', data);
      const response = await axios.post(API.POSTSELECTEDINVOICE, data);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

const InvoiceSlice = createSlice({
  name: 'Invoice',
  initialState,
  reducers: {
    updateUploadLoading: (state) => {
      state.isUploadLoading = !state.isUploadLoading;
    },
    emptyStatusMessage:(state)=>{
      state.message=initialState.message,
      state.statusCode=initialState.statusCode
    },
    emptyMatch:(state) => {
      if(state.matchFile){
        state.matchFile.status = initialState.matchFile.status;
        state.matchFile.message = initialState.matchFile.message;
      }},
      emptySelectedInvoice:(state) => {
        state.isError=initialState.isError
        state.isLoading=initialState.isLoading
        state.isUploadLoading=initialState.isUploadLoading
        state.matchFile=initialState.matchFile
        state.message=initialState.message
        state.status=initialState.status
        state.statusCode = initialState.statusCode
        state.uploadedFile=initialState.uploadedFile
      }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoices.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.status = 'loading';
      })
      .addCase(fetchInvoices.fulfilled, (state, action: PayloadAction<{data:InvoiceData,status:number}>) => {
        state.isLoading = false;
        state.data = action.payload.data.data;
        state.totalDocuments=action.payload.data.totalDocuments;
        state.totalPages=action.payload.data.totalPages
        console.log(action.payload , "actionnn");
        state.status = 'succeeded';
        state.statusCode = action.payload.status;
        state.message = 'Data fetched successfully';
      })
      .addCase(fetchInvoices.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isError = true;
        state.status = 'failed';
        state.message = action.payload ? action.payload : 'Fetch failed';
      })
     
      .addCase(postUploadInvoices.pending, (state) => {
        state.isUploadLoading = true; 
        state.isError = false;
        state.status = 'uploading';
      })
      .addCase(postUploadInvoices.fulfilled, (state, action: PayloadAction<AxiosResponse<any>>) => {
        state.isUploadLoading = false; 
        state.status = action.payload.status
        state.statusCode = action.payload.status; 
        state.data.push(action.payload.data.invoice)
        state.message = action.payload.data.message;
      
      })
      .addCase(postUploadInvoices.rejected, (state, action: PayloadAction<any>) => {
        state.isUploadLoading = false; 
        state.isError = true;
        state.status = 'upload_failed';
        state.message = action.payload ? action.payload : 'Upload failed';
      })
      .addCase(getSelectedInvoice.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.status = 'loading';
      })
      .addCase(getSelectedInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        if(state.uploadedFile){
          state.uploadedFile=action.payload.data
          state.uploadedFile.status = action.payload.status;
          if(action.payload.data?.message) state.uploadedFile.message = action.payload.data.message;
        }
        state.status = 'succeeded';
        state.statusCode = 200;
        state.message = 'Selected invoice fetched successfully';
      })
      .addCase(getSelectedInvoice.rejected, (state, action:PayloadAction<any>) => {
        state.isLoading = false;
        state.isError = true;
        if(state.uploadedFile){
          state.uploadedFile.status = action.payload.status;
          if(action.payload.data?.message) state.uploadedFile.message = action.payload.data.message;
        }
        state.status = 'failed';
        
      })
      .addCase(PostmatchInvoices.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.status = 'loading';
      })
      .addCase(PostmatchInvoices.fulfilled, (state, action) => {
        state.isLoading = false;
        if(state.matchFile){
          state.uploadedFile = action.payload.data.invoice
          state.matchFile.status = action.payload.status;
          state.matchFile.message = action.payload.data.message;
       } 
        state.status = 'succeeded';
      })
      .addCase(PostmatchInvoices.rejected, (state, action:PayloadAction<any>) => {
        state.isLoading = false;
        state.isError = true;
        state.status = 'failed';
        if(state.matchFile){
          state.matchFile.status = action.payload.status;
          state.matchFile.message = action.payload.data.message;
       } 
        
      });
     
      
  },
});

export const { emptySelectedInvoice, emptyMatch, updateUploadLoading,emptyStatusMessage } = InvoiceSlice.actions;
// export const Account=(state:RootState)=>state.invoiceReducer.i
export const InvoiceData = (state: RootState) => state.invoiceReducer.data;
export default InvoiceSlice.reducer;
