import React, { useRef, useState } from "react";
import Header from "./Header";
import ApprovalStatus from "./ApprovalStatus";
import InputField from "./InputField";
import TextArea from "./TextArea";
import ApprovalInfo from "./ApprovalInfo";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { status } from "../../redux/reducers/budgetreqReducer";
import moment from "moment";
import {
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import Loader from "../../common-components/Loader";
import { formatCurrency } from "../../common-components/commonFunctions";

interface MyComponentProps {
  isOpen: boolean;
  onClose: () => void;
}

const UploadInvoices: React.FC<MyComponentProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const [isPopupOpen, setIsPopupOpen] = useState(false);
    const isLoading=useSelector((state:RootState)=>state.budgetReducer.isLoading)
  console.log(isLoading,"isloading")
  const handleToggle = () => {
    setIsPopupOpen(true);
  };


  const selectedBudget = useSelector(
    (state: RootState) => state.budgetReducer.selectedBudgetRow
  );

  const currentStatus = useSelector(status);
  const statusText =
    currentStatus === 1 ? "Approved" : currentStatus === 2 ? "Declined" : "";
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.files);
  };
  const handleSVGClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <div className="fixed inset-0 z-20 flex items-center justify-end">
        <div className="bg-white z-10 w-[700px] h-screen overflow-hidden flex flex-col">
          <main className="flex flex-col flex-grow overflow-y-auto overflow-x-hidden">
            
            <section className="flex flex-col flex-grow ">
           
              <Header
                title={
                  currentStatus === 1
                    ? "Approved Request"
                    : currentStatus === 0
                    ? "New Request"
                    : "Declined Request"
                }
                onClose={onClose}
              />
              {/* <button
          className=""
          onClick={onClose}
        >bu</button> */}
              <div className="p-6">
                <div className="  flex flex-col flex-grow">
               
                <Loader isLoading={isLoading} >
                 
                  <div className="flex items-center mb-6">
                    {currentStatus === 1 ? (
                      <div
                        className={`w-4/5 ${currentStatus === 1 ? "pr-4" : ""}`}
                      >
                        <ApprovalStatus statusOfForm={statusText} />
                      </div>
                    ) : currentStatus === 2 ? (
                      <div className="w-full">
                        <ApprovalStatus statusOfForm={statusText} />
                      </div>
                    ) : currentStatus === 0 ? (
                      <div className="p-0 m-0"></div>
                    ) : (
                      ""
                    )}

                    {currentStatus === 1 && (
                      <div className="w-1/5">
                        <button
                          className="w-[150px] text-[15px] h-[40px] cursor-pointer border border-rose-100 bg-transparent text-black rounded font-bold font-poppins "
                          onClick={handleToggle}
                        >
                          Attach Invoices
                        </button>
                      </div>
                    )}
                  </div>
                  </Loader>
                  <div className="flex gap-6 items-start w-full text-base leading-none flex-wrap">
                    <InputField
                      label="Account Name"
                      value={selectedBudget?.budget?.account_name ?? 0}
                    />
                    <InputField
                      label="Account Number"
                      value={selectedBudget?.budget?.account_number ?? 0}
                    />
                  </div>
                  <div className="flex gap-6 items-start mt-6 w-full text-base leading-none flex-wrap">
                    <InputField
                      label="COOP Type"
                      value={selectedBudget?.budget?.coopType ?? 0}
                    />
                    <InputField
                      label="Claim Number"
                      value={selectedBudget?.budget?.claim_number?.length ? selectedBudget?.budget?.claim_number : "NA"}
                    />
                  </div>
                  <div className="flex gap-6 items-start mt-6 w-full text-base leading-none flex-wrap">
                    <div className="flex flex-col text-[14px] flex-1 shrink w-full basis-0 min-w-[240px] max-md:max-w-full overflow-hidden">
                      <label className="self-start text-[14px] font-semibold leading-none text-neutral-800">
                        Selected ISBN
                      </label>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        className="mt-2.5 font-14-imp text-neutral-500 rounded-md border border-solid bg-zinc-100 border-neutral-200"
                        multiple
                        value={selectedBudget?.budget?.isbn}
                        renderValue={(selected) =>
                          selected
                            .map(
                              (item, index) =>
                                `${item}${
                                  index < selected.length - 1 ? "," : ""
                                }`
                            )
                            .join(" ")
                        }
                      >
                        <div className="max-h-[200px] text-[14px]">
                          {selectedBudget?.budget?.isbn.map((isbn) => (
                            <MenuItem key={isbn} value={isbn}>
                              <Checkbox checked={!!isbn} />
                              <ListItemText primary={isbn} />
                            </MenuItem>
                          ))}
                        </div>
                      </Select>
                    </div>
                  </div>

                  <div className="flex gap-6 items-start mt-6 w-full leading-none flex-wrap">
                    <div className="flex flex-col flex-1 shrink w-full basis-0  max-md:max-w-full overflow-hidden">
                      <label className="self-start text-[14px] font-semibold leading-none text-neutral-800">
                        Selected Title
                      </label>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        className="mt-2.5 font-14-imp text-neutral-500 rounded-md border border-solid bg-zinc-100 border-neutral-200"
                        multiple
                        value={selectedBudget?.budget?.title}
                        renderValue={(selected) =>
                          selected
                            .map(
                              (item, index) =>
                                `${item}${
                                  index < selected.length - 1 ? "," : ""
                                }`
                            )
                            .join(" ")
                        }
                      >
                        <div className="max-h-[200px]  ">
                          {selectedBudget?.budget?.title.map((title) => (
                            <MenuItem key={title} value={title} className="">
                              <Checkbox checked={!!title} className="" />
                              <ListItemText
                                primary={title}
                                className=" max-w-10 "
                              />
                            </MenuItem>
                          ))}
                        </div>
                      </Select>
                    </div>
                  </div>
                  <div className="flex gap-6 items-start mt-6 w-full leading-none flex-wrap">
                    <InputField
                      label="Request Created Date"
                      value={
                        moment(selectedBudget?.budget?.createdAt).format(
                          "MM-DD-YYYY"
                        ) ?? 0
                      }
                    />
                    <InputField
                      label="Request Approved Date"
                      value={
                        selectedBudget?.budget?.status === 1 ? moment(selectedBudget?.budget?.updatedAt).format(
                          "MM-DD-YYYY"
                        ) : "N/A"
                      }
                    />
                  </div>
                  <div className="mt-6">
                    <InputField
                      label="Approval By(Deadline)"
                      value={
                        moment(selectedBudget?.budget?.deadline).format(
                          "MM-DD-YYYY"
                        ) ?? 0
                      }
                    />
                  </div>
                  <div className="w-full mt-6">
                    <TextArea
                      label="Description"
                      value={selectedBudget?.budget?.description?.length ? selectedBudget?.budget?.description : "NA"}
                    />
                    <TextArea
                      label="Notes"
                      value={selectedBudget?.budget?.notes?.length ? selectedBudget?.budget?.notes : "NA"}
                    />
                  </div>
                  <div className="flex gap-6 items-center mt-6 w-full whitespace-nowrap ">
                    <InputField
                      label="Requested Amount"
                      value={`${
                        selectedBudget?.budget?.required_amount_budget ? formatCurrency(selectedBudget?.budget?.required_amount_budget) : 0
                      }`}
                      isLarge
                      className={"mb-2"}
                    />
                  </div>
                  <ApprovalInfo
                    onClose={onClose}
                    approverName="John Dee"
                    approverImageSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/d7ed4be7d19454579b3192cd68edfa7710098bb61c66eecfd2c6ffb1c03297ca?apiKey=a88c36c4e69e48eb98243259c870fe00&&apiKey=a88c36c4e69e48eb98243259c870fe00"
                    comments="
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada. Nullam ac dui vitae velit feugiat convallis vel id dui."
                  />
                </div>
              </div>
            </section>
            
          </main>
        </div>
        <div
          className="fixed inset-0 bg-black opacity-50"
          // onClick={onClose}
        ></div>
      </div>
      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end items-center z-20 ">
          <div className="bg-rose-100">
            <div className="  bg-white rounded-lg w-[700px] h-screen  ">
              <div className="flex justify-center bg-rose-100 mb-10">
                <h2 className="text-[25px] ml-5  bg-rose-100 w-full rounded-md">
                  Upload Invoice
                </h2>
                <button
                  onClick={onClose}
                  className="flex gap-1.5 items-center self-stretch px-1 py-0.5 my-auto text-base font-black text-center whitespace-nowrap rounded-md border border-solid
         border-slate-500 border-opacity-20 text-slate-500 mr-10 cursor-pointer  "
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 6L6 18M6 6l12 12"
                      stroke="#808080"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <form className="m-6  ">
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  accept="audio/mp3"
                />
                <div className="w-full border-2 h-[90px] border-gray-300 border-dashed rounded-md font-poppins custom-placeholder flex justify-center items-center ">
                  <span className="absolute">Write Here</span>
                  <div className="mt-[50px]">
                    <svg
                      onClick={handleSVGClick}
                      className="cursor-pointer"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8L14 2Z"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 16V8M12 16L8 12M12 16L16 12"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 16H8C6.9 16 6 16.9 6 18C6 19.1 6.9 20 8 20H16C17.1 20 18 19.1 18 18C18 16.9 17.1 16 16 16Z"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="  flex justify-between gap-3 mt-6 ">
                  <button
                    type="button"
                    className="bg-red-600 font-bold cursor-pointer text-[15px] font-poppins  text-white w-full h-12 rounded-md"
                    onClick={onClose}
                  >
                    Upload
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UploadInvoices;

