import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios from "axios";
import { API } from "../../constants/API";

interface checkObj {
  status?:number;
  message?:string;
}

interface sendMailObj {
  mailOnceSent?:boolean;
  status?:number;
  message?:string;
}

interface forgotPassState {
  err?: string;
  status?: string;
  checkToken?:checkObj;
  setPass?:checkObj;
  sendMail?:sendMailObj;
}

const initialState: forgotPassState = {
    err: "",
    status:'',
    setPass:{
      status:0,
      message:''
    },
    checkToken:{
      status:0,
      message:''
    },
    sendMail:{
        mailOnceSent : false,
        status:0,
        message:''      
    }
};

export const setPassword = createAsyncThunk(
  "setPassword",
  async (data:any,{rejectWithValue}) => {
    try{
      const response = await axios.post(API.SETPASSWORD, data);
      return response;
    }catch(err:any){
      return rejectWithValue(err.response)
    }
  }
)


export const checkForgotToken = createAsyncThunk(
  "checkForgotToken",
  async (data:any,{rejectWithValue}) => {
    try{
      const response = await axios.post(API.CHECKFORGOTTOKEN, data);
      return response;
    }catch(err:any){
      return rejectWithValue(err.response)
    }
  }
)

export const sendForgotMail = createAsyncThunk(
    "sendForgotMail",
    async (data:any,{rejectWithValue}) => {
      try{
        const response = await axios.post(API.SENDFORGOTMAIL, data);
        return response;
      }catch(err:any){
        return rejectWithValue(err.response)
      }
    }
)

export const forgotPassSlice = createSlice({
  name: "forgotPass",
  initialState,
  reducers: {
    emptyforgotPassObj : (state) => {
      state.status = '';
      state.err = '';
      if(state.checkToken){  
        state.checkToken.status = 0;
        state.checkToken.message = '';
      }
      if(state.sendMail){ 
        state.sendMail.mailOnceSent = false; 
        state.sendMail.status = 0;
        state.sendMail.message = '';
      }
      if(state.setPass){  
        state.setPass.status = 0;
        state.setPass.message = '';
      }
    }
  },
  extraReducers: (builder) => {
      // on boarding set Password
    builder
      .addCase(setPassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(setPassword.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded'  
        if(state.setPass){
          state.setPass.status = action.payload.status;
          state.setPass.message = action.payload.data.message;
        }
      })
      .addCase(setPassword.rejected, (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if(state.setPass){
          state.setPass.status = action.payload.status;
          state.setPass.message = action.payload.data.message;
        }
        state.err = action.payload;
      });

    builder
        .addCase(checkForgotToken.pending, (state) => {
          state.status = "loading";
        })
        .addCase(checkForgotToken.fulfilled, (state, action: PayloadAction<any>) => {
          state.status = 'succeeded'  
          if(state.checkToken){  
            state.checkToken.status = action.payload.status;
            state.checkToken.message = action.payload.data.message;
          }
        })
        .addCase(checkForgotToken.rejected, (state, action: PayloadAction<any>) => {
          state.status = "failed";
          if(state.checkToken){  
            state.checkToken.status = action.payload.status;
            state.checkToken.message = action.payload.data.message;
          }
          state.err = action.payload;
        });
  
    builder
        .addCase(sendForgotMail.pending, (state) => {
            state.status = "loading";
        })
        .addCase(sendForgotMail.fulfilled, (state, action: PayloadAction<any>) => {
            state.status = 'succeeded'  
            if(state.sendMail){
            state.sendMail.mailOnceSent = true;
            state.sendMail.status = action.payload.status;
            state.sendMail.message = action.payload.data.message;
            }
        })
        .addCase(sendForgotMail.rejected, (state, action: PayloadAction<any>) => {
            state.status = "failed";
            if(state.sendMail){
            state.sendMail.status = action.payload.status;
            state.sendMail.message = action.payload.data.message;
            }
            state.err = action.payload;
        });
  },
});

export const { emptyforgotPassObj } = forgotPassSlice.actions;


export const forgotPassData = (state: RootState) => state.forgotPasswordReducer;
export const mailSentOnce = (state: RootState) => state.forgotPasswordReducer.sendMail;

export default forgotPassSlice.reducer;
