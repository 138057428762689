import React from 'react';
import Logo from './Logo';
import ForgotPasswordForm from './ForgotPasswordForm';
import Hero from '../../../common-components/HeroSection';

const ForgotPasswordPage: React.FC = () => {
  return (
    // <main className="flex flex-col  items-center  bg-white min-h-screen ">
    //   <div className="flex flex-col lg:flex-row w-full ">
    //     <section className="flex flex-col items-center justify-center lg:w-1/2 p-4">
    //       <div className="flex flex-col ">
    //         {/* <Logo /> */}
    //         <ForgotPasswordForm />
    //       </div>
    //     </section>    
    //       <Hero />
    //   </div>
    // </main>
    <main className="">
      <section className="flex justify-center items-center">
      <ForgotPasswordForm />
          <Hero/>
      </section>
    </main>
  );
};

export default ForgotPasswordPage;