import * as React from "react";

interface EmailInputProps {
  id: string;
  name:string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (data: any) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  defaultValue?: string;
  value?: string;
  error?: any;
}

const EmailInput: React.FC<EmailInputProps> = ({ 
  id, 
  name,
  onChange,
  onClick,
  onBlur,
  value,
  defaultValue,
  error,
}) => {
  return (
    <div className="flex relative flex-col max-w-full  rounded-md w-[466px]">
      <label htmlFor={id} className="sr-only">
        Email address
      </label>
      <input 
        type="email"
        id={id}
        className="px-6 py-3 rounded-md font-poppins text-[15px] bg-zinc-100 max-md:px-5 max-md:max-w-full"
        placeholder="Email address"
        aria-label="Email address"
        onClick={onClick}
        defaultValue={defaultValue}
        value={value} 
        onChange={onChange} 
        onBlur={onBlur} 
        name={name}
      />
      {
        error &&
       (<span className="text-red-500 flex justify-start absolute bottom-[-20px] left-1">{error.message}</span>)
      }
    </div>
  );
};

export default EmailInput;

