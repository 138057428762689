import React, { useEffect, useState } from "react";
import { CommonDataTable } from "../../common-components/common-material-react-table/CommonMaterialTable";
import {
  dashboardData,
  dashboardHeaders,
} from "../../constants/tableConstants/tableHeaders";
import {
  MRT_GlobalFilterTextField, 
} from 'material-react-table';
import { useDispatch, useSelector } from "react-redux";
import {
  adjustPageAndLimit,
  emptyStatus,
  CurrentPage,
  dashboard,
  dashboardStatus,
  decreasePageAndlimit,
  emptyAccount,
  fetchDashBoard,
  Limit,
  setUnchangedTotalSummary,
  updateDashboard,
} from "../../redux/reducers/dashboardReducer";
import { AppDispatch, RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { FaInfoCircle, FaRegEye } from "react-icons/fa";
import { Dropdown } from "../../common-components/dropdown/DropDown";
import { MdCancel } from "react-icons/md";
import { fetchAllBudgetReqs } from "../../redux/reducers/budgetreqReducer";
import { formatCurrency } from "../../common-components/commonFunctions";
import Loader from "../../common-components/Loader";

interface Account {
  id?: number;
  Account_name?: string;
  Account_number?: number;
  Budget?: string;
  Remaining?: string;
  incurred?: string;
}

interface selectedTabObject {
  tabName?: string;
  tabValue?: string;
}


const AccountInfoTable = () => {
  const [selectedTab, setSelectedTab] = useState<selectedTabObject>({
    tabName: "Account Information",
    tabValue: "account_information"
  });

  const dashboardTabs = [
    {
      tabName: "Account Information",
      tabValue: "account_information"
    },
    {
      tabName: "COOP Summary",
      tabValue: "coop_summary"
    }
  ]
  
  const [searchTerm, setSearchTerm] = useState("");
  const accounts = useSelector((state: RootState) => state.dashBoardReducer.accounts);
  const statusCode = useSelector((state: RootState) => state.dashBoardReducer.statusCode);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const limit = useSelector((state: RootState) => state.dashBoardReducer.limit);
  const totalPages = useSelector((state:RootState) => state.dashBoardReducer.totalPages)
  const totalDocuments = useSelector((state:RootState) => state.dashBoardReducer.totalDocuments)
  
  const navigate=useNavigate()
  const dispatch = useDispatch<AppDispatch>();

  const isLoading = useSelector((state:RootState) => state.dashBoardReducer.loading)
  // const [isResetActive, setIsResetActive] = useState(false); 
  
  const handleRowClick = (rowData:any, index:number) => {
    navigate(`/viewDetails/${rowData.Account_id}`);
    // setSelectedRowIndex(index);
    // dispatch(updateDashboard(rowData));
    // if (isResetActive) {
    //   setIsResetActive(false); 
    // }
  };

  useEffect(() => {
    if(statusCode === 200) {
      dispatch(adjustPageAndLimit())
      dispatch(emptyStatus())
    }
  },[statusCode])

  useEffect(() => {
    dispatch(fetchDashBoard({ currentPage: 1, limit: limit, searchTerm:searchTerm }));
  },[])
  
  // const handleSetData = (data: any) => {
  //   navigate(`/viewDetails/${data.Account_id}`);
  //   if (isResetActive) {
  //     setIsResetActive(false); 
  //   }
  // };
  // const handleReset = () => {
  //   dispatch(setUnchangedTotalSummary());
  //   setSelectedRowIndex(null)
  //   setIsResetActive(true);
  // };
  // useEffect(() => {
  //   setIsResetActive(true); 
  // }, []);
  
  const handleNextCall=(page:number)=>{
    dispatch(fetchDashBoard({ currentPage: page, limit: limit, searchTerm:searchTerm }));
  }

  const handleSearch = () => {
    const trimmedSearchTerm = searchTerm.trim();
    setSearchTerm(trimmedSearchTerm)
    // console.log(searchTerm,"search")
    dispatch(fetchDashBoard({ currentPage: 1, limit: limit, searchTerm:searchTerm.trim() }));
  }

  return (
    <section className="flex lg:w-full lg:mt-6 md:mt-3 flex-col text-sm text-zinc-950">
      <div className="flex flex-wrap w-full text-lg font-medium text-center text-zinc-950 max-md:max-w-full">
          <CommonDataTable
            tableHeaders={dashboardHeaders}
            showRowNumbers
            data={accounts}
            totalPages={totalPages || 0}
            totalDocuments={totalDocuments || 0}
            tableCursor="pointer"
            callback={(rowData, rowIndex) => handleRowClick(rowData, rowIndex)}
            selectedIndex={selectedRowIndex}
            nextCallback={handleNextCall}
            actionButtons
            topToolbar={({ table }) => (
              <div className="flex text-sm font-bold px-6 gap-2 justify-between items-center p-3">
                  <div className="flex justify-between items-center cursor-pointer gap-5">
                    {
                      dashboardTabs.map(tab => (
                        <p
                          className={` shadow-md py-[5px] shadow-gray-300  px-[5px] ${
                            tab.tabValue === selectedTab.tabValue &&
                            "border-b-[3px] border-solid border-red-600"
                          }`}
                          onClick={() => setSelectedTab(tab)}
                        >
                          {tab.tabName}
                        </p>
                      ))
                    }
                  </div>
                  <div className=" gap-2 flex items-center justify-end">
                    <div className="flex w-full justify-end ">
                      <div className="relative">
                        <input
                          type="text"
                          onKeyDown={(event) => {
                            if(event.key === "Enter")
                              dispatch(fetchDashBoard({ currentPage: 1, limit: limit, searchTerm:searchTerm }));
                          }}
                          // title="Search Account Number, Account Number"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          placeholder="Search Account Name and Account Number"
                          className="border border-solid border-neutral-200 font-poppins rounded-lg  mr-2 focus:border-blue-500 focus:outline-none p-2 w-[500px]"
                        />
                            {searchTerm?.length ?<div title="Clear All" onClick={() =>{
                              setSearchTerm("")
                              dispatch(fetchDashBoard({ currentPage: 1, limit: limit, searchTerm:"" }));
                            }} 
                            className="flex absolute items-center right-5 top-2.5 ">
                            <MdCancel className="text-red-600 w-5 h-5 cursor-pointer"/>
                            {/* <ConfirmDialog /> */}
                          </div>:""}
                      </div>
                      <div className="">
                      <button
                        className="p-[9px] font-poppins bg-red-600 text-white rounded-lg cursor-pointer "
                        onClick={handleSearch}  
                      >
                        Search
                      </button>
                    </div>
                    {/* <div className="pl-2">
                      <button title="View total budget" className={`p-[9px]
                      text-black cursor-pointer  font-poppins  rounded-lg ${isResetActive ? 'bg-slate-200' : 'bg-yellow-300'} `} 
                      onClick={handleReset}>
                        Reset Filters
                      </button>
                    </div> */}
                    </div>
                  
                  </div>
              </div>
            )}
            pageSize={limit}
            colSizes={[180, 180, 130,120,120]}
            enableTopToolbar={true}
            // customActionButton={(row : any) => (
            //   <div className="flex justify-center items-center m-2"> 
            //   <div className="text-gray-800 dark:text-white border rounded
            //   ">
            //     <span title="Account Details" className="cursor-pointer"  
            //     onClick={() => handleSetData(row.original)} ><FaRegEye  className="w-5 h-5 "/> </span>
            //   </div>
            //   </div>
            // )}
            changeSelectedColumnDataDesign={["Budget","Remaining","incurred"]}
            changedDataCellColumn={(header, accessor) => {
              if (accessor === "Budget") {
                return {
                  accessorKey: accessor,
                  header: header,
                  Cell: ({ row }: { row: any }) =>{
                    // console.log(row)
                    return(
                      <div>
                        {formatCurrency(row.original.Budget)}
                      </div>
                  )}}} else if (accessor === "Remaining") {
                    return {
                      accessorKey: accessor,
                      header: header,
                      Cell: ({ row }: { row: any }) =>{
                        // console.log(row)
                        return(
                          <div>
                            {formatCurrency(row.original.Remaining)}
                          </div>
                      )}}} else if (accessor === "incurred") {
                        return {
                          accessorKey: accessor,
                          header: header,
                          Cell: ({ row }: { row: any }) =>{
                            // console.log(row)
                            return(
                              <div>
                                {formatCurrency(row.original.incurred)}
                              </div>
                          )}}}

                return {
                  accessorKey: accessor,
                  header: header,
                }
            }}
          />
      </div>
    </section>

  );
};

export default AccountInfoTable;
