import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { CommonDataTable } from '../../common-components/common-material-react-table/CommonMaterialTable';
import { useDispatch, useSelector } from 'react-redux';
import { ISBNTableHeaders } from '../../constants/tableConstants/tableHeaders';
import { emptySelectedIsbn, selectedIsbnData } from '../../redux/reducers/budgetreqReducer';
import { AppDispatch } from '../../redux/store';

interface optionObj{
  value:string | number;
  label:string | number;
}

interface modalProps {
    showModal:boolean;
    setShowModal:(show: boolean) => void;
    data?:optionObj[]
}

const BudgetIsbnModal : React.FC<modalProps> = ({showModal, setShowModal, data}) => {
  const dispatch = useDispatch<AppDispatch>()
  const selectedIsbn = useSelector(selectedIsbnData)
  const handleClose = () => {
    dispatch(emptySelectedIsbn())
    setShowModal(false)
  }
  return (
    
    <Dialog open={showModal} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle className="font-poppins " >ISBN/Titles List</DialogTitle>
        <DialogContent>
          <div className="  font-poppins  p-4 bg-white w-[95%]">
            <div className="mb-4">
              <CommonDataTable 
                data={data ? data.map(isbn => {return ({isbn: isbn.value, title:isbn.label})}) : selectedIsbn ?? []}
                tableHeaders={ISBNTableHeaders}
                colSizes={[100,100]}
                callback={() => {}}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className=" flex cursor-pointer items-center mt-5 justify-end font-bold text-[15px] text-white rounded-md  bg-red-600 text-center border-red-600 border">
            <span className="p-2 w-full font-poppins rounded text-white  bg-red-600 flex justify-center items-center">
              Close
            </span>
          </Button>
        </DialogActions>
    </Dialog> 
  )
}

export default BudgetIsbnModal