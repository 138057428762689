import React, { useEffect } from 'react';
import AccountDetails from './AccountDetails';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { emptyAccountDetails } from '../../redux/reducers/AccountDetailReducer';



const ViewDetails: React.FC= () => {
  const dispatch=useDispatch<AppDispatch>()
  const params =useParams()
  useEffect(()=>{
      return()=>{
        dispatch(emptyAccountDetails())
      }
  },[params])

// const navigate=useNavigate()
  return (
    <div className="rounded-lg mx-9">
      <div className='w-full flex items-center justify-between '>
        <h2 className="text-xl font-semibold mb-4 flex-start ">Account Details</h2>
      </div>
    <AccountDetails  />
    </div>
  );
};

export default ViewDetails;
