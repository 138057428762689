import React, { useEffect, useRef, useState } from 'react'
import { CommonDataTable } from '../../common-components/common-material-react-table/CommonMaterialTable'
import { invoicedata, InvoicesHeaders } from '../../constants/tableConstants/tableHeaders'
import NewReq from '../../common-components/New request/newReq'
import { FaCross, FaDownload, FaEye, FaFileUpload, FaRegEye, FaRegFilePdf, FaUpload } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { userProfile } from '../../redux/reducers/profileReducer'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TableCell,
} from "@mui/material";
import { user } from '../../redux/reducers/UserReducer'
import { useNavigate, useParams } from 'react-router-dom'
import { AppDispatch, RootState } from '../../redux/store'
import { emptyMatch, emptySelectedInvoice, emptyStatusMessage, fetchInvoices, postUploadInvoices, updateUploadLoading } from '../../redux/reducers/InvoiceReducer'
import { InvoiceData } from '../../redux/reducers/InvoiceReducer'
import moment from 'moment'
import { toast } from 'react-toastify'
import { MdCancel, MdVerified } from 'react-icons/md'

// import { useNavigate } from 'react-router-dom'

const BudgetRequest = () => {
  const invoices = useSelector((state: RootState) => state.invoiceReducer.data);
  const userData = useSelector(user)
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string | null>(null); 
  const uploadedFile = useSelector((state: RootState) => state.invoiceReducer.uploadedFile);
  const statusCode=useSelector((state:RootState)=>state.invoiceReducer.statusCode)
  const message=useSelector((state:RootState)=>state.invoiceReducer.message)
  const isUploading=useSelector((state:RootState)=>state.invoiceReducer.isUploadLoading)
  const navigate=useNavigate()
  const dispatch: AppDispatch = useDispatch();
  const fileInputRef=useRef<HTMLInputElement>(null)
  const totalDocuments=useSelector((state:RootState)=>state.invoiceReducer.totalDocuments)
  const totalPages=useSelector((state:RootState)=>state.invoiceReducer.totalPages)
  const matchStatus = useSelector((state:RootState) => state.invoiceReducer.matchFile.status)
  const matchMessage = useSelector((state:RootState) => state.invoiceReducer.matchFile.message)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFile(null);
    setFileName('');
    setError(null);
    
  };
  
  useEffect(() => {
    dispatch(fetchInvoices({page:1,limit:10} ));
  }, [dispatch]);



useEffect(()=>{
  if(statusCode===201){
    toast.success(message)
  }else if(statusCode===404){
    toast.error(message)
  }
  dispatch(emptyStatusMessage())
  handleClose()
},[statusCode,message])

const handleSVGClick = () => {
  if (fileInputRef.current) {
    fileInputRef.current.click();
  }
};

const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files?.[0];
  if (file) {
     setFile(file);
     setFileName(file.name);
     setError(null);
    if (fileInputRef.current) fileInputRef.current.value = '';
  }
};

const handleRemoveFile = () => {
  setFile(null);
  setFileName('');
  if (fileInputRef.current) fileInputRef.current.value = '';
};

const handleClosemodel=()=>{
  setOpen(false);
  setFile(null);
  setFileName('');
}

const handleVerify = (data: any) => {
  if (data && data._id) {
    navigate(`/VerifyInvoices/${data._id}`);
  } else {
    console.error("Invoice data is invalid");
  }
};
const handleUpload = () => {
  if (!file) {
    setError("Click here to upload invoice")
    toast.error(message);
    return;
  }
  setError("");
  dispatch(postUploadInvoices(file)) 

};
const handleNextCallback=( page:number )=>{
  dispatch(fetchInvoices({page:page,limit:10}))
}


const handleDownload=(Invoice_URL:string)=>{
  window.open(Invoice_URL)
  // const link=document.createElement('a');
  // link.href=Invoice_URL;
  // link.download = Invoice_URL.split('/').pop() || 'invoice.pdf';
  // document.body.appendChild(link);
  // link.click();
}

// const handleDownload=async (Invoice_URL:string)=>{
//   try {
//     const response = await fetch(Invoice_URL, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/pdf',
//       },
//     });

//     if (!response.ok) {
//       throw new Error('Network response was not ok.');
//     }

//     const blob = await response.blob();
//     const link = document.createElement('a');
//     link.href = window.URL.createObjectURL(blob);
//     link.download = fileName;
//     link.click();

//     // Clean up
//     window.URL.revokeObjectURL(link.href);
//   } catch (error) {
//     console.error('Error downloading the file:', error);
//   }


// }

useEffect(() => {
  if(matchStatus === 200){
    toast.success(matchMessage);
    dispatch(emptyMatch())
    dispatch(emptySelectedInvoice())
  }
},[matchStatus,matchMessage])


  return (
    <div className='font-poppins mx-9'>
      <section>
        <NewReq title={`${(userData?.role?.includes('requestor') || userData?.role?.includes('admin')) ? 'Upload' : 'Verify'} Invoices`}
         element={
          <div>
            {
              (userData?.role?.includes('requestor') || userData?.role?.includes('admin')) ? 
              <button  onClick={handleClickOpen}
                className="flex font-poppins p-2 bg-red-600 rounded-lg text-[13px] font-semibold text-white cursor-pointer"
              >
             +   New Invoice
              </button>
          : <></>
            }
          </div>
            } />
        <div className="">
          <div className="">
            <CommonDataTable

                changeSelectedColumnDataDesign={["Status", "updatedAt"]}
                changedDataCellColumn={(header, accessor) => {
                  if (accessor === "Status") {
                    return {
                      accessorKey: accessor,
                      header: header,
                      Cell: ({ row }: { row: any }) => (
                        <div>
                              {  
                        row.original.Invoice_status===0 
                        ?
                        <div className='text-yellow-500 font-poppins ' >Pending</div>
                      :
                      row.original.Invoice_status===1 
                      ? 
                        <span 
                        className='text-green-500 text-[14px] font-semibold font-poppins' > Verified</span> 
                      : 
                        <div className='text-red-500 text-[14px] font-semibold font-poppins '>Declined</div> }
                        </div>
                      ),
                    };
                  } else if (accessor === "updatedAt") {
                    return {
                      accessorKey: accessor,
                      header: header,
                      Cell: ({ row }: { row: any }) => (
                        <div>
                          {moment(row.original.updatedAt).format("MM DD YYYY")}
                        </div>
                      ),
                    };
                  } 
                  
                  return {
                    accessorKey: accessor,
                    header: header,
                  };
                }}

              tableHeaders={InvoicesHeaders}
              data={invoices}
              showRowNumbers
              pageSize={10}
              callback={() => {}}
              colSizes={[220, 220, 220]} 
              nextCallback={handleNextCallback}
              enableGlobalFilter={false}
              actionButtons
              totalDocuments={totalDocuments}
              totalPages={totalPages}
           
              customActionButton={(row : any) => (
                  <div className="flex justify-center items-center "> 
                  <div
                  className=" ">
                    <span  className="cursor-pointer m-2"
                  onClick={() => handleVerify(row.original)} >
                    <FaRegEye className=' border border-none bg-transparent  w-5 h-4 ' title='View Invoice'/></span>
                  <span
                 
                   className='cursor-pointer'
                    title="Download Invoice"
                    > 
                    {/* <a href= {row.original.Invoice_URL} 
                     download={row.original.Invoice_URL}
                     title="Download Invoice"> */}
                      <FaDownload className=' w-5 h-4'   onClick={() => handleDownload(row.original.Invoice_URL)}  />
                      {/* </a>   */}
                      </span>
                  </div>
                  {/* {  
                  row.original.Invoice_status===0 
                  ?
                  <></>
                :
                 row.original.Invoice_status===1 
                 ? 
                  <span onClick={() => handleVerify(row.original)} 
                  className='text-green-500 text-[14px] font-semibold cursor-pointer' > Verified</span> 
                 : 
                  <div onClick={() => handleVerify(row.original)} className='text-red-500 text-[14px] font-semibold cursor-pointer'>Declined</div> } */}
                  </div>  
              )}
            />
          </div>
        </div>
      </section>
    <div>
      
    </div>
    {
      open &&  (
        <div   className="fixed inset-0 bg-black bg-opacity-50 flex justify-end items-center z-20 ">
          <div className="bg-rose-100">
            <div className="  bg-white rounded-lg w-[700px] h-screen  ">
              <div className="flex justify-center bg-rose-100 mb-10">
                <h2 className="text-[25px] ml-5  bg-rose-100 w-full rounded-md">
                  Upload Invoice
                </h2>
                <button
                onClick={handleClosemodel}
                 
                  className="flex gap-1.5 items-center self-stretch px-1 py-0.5 my-auto text-base font-black text-center whitespace-nowrap rounded-md border border-solid
         border-slate-500 border-opacity-20 text-slate-500 mr-10 cursor-pointer  "
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 6L6 18M6 6l12 12"
                      stroke="#808080"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div>
              {fileName && (
        <div className="flex justify-center text-center m-0 p-0 ">
          <div className="relative flex flex-col border-[1px] rounded-[15px] p-[10px] border-dashed items-center">
          <div>
            <FaRegFilePdf className='w-[40px] h-[40px]' />
          </div>
          <div>
            <p className="text-gray-700 font-semibold">File Name: {fileName}</p>
          </div>
            <div className="z-30 absolute top-0 right-[-10px] font-semibold  text-red-700 cursor-pointer h-1 flex items-center justify-center text-sm rounded-lgbg-black " onClick={handleRemoveFile} > 
            <MdCancel className="  text-red-600 w-7 h-7 cursor-pointer" title='Remove File' />
            </div>
          </div>
        </div>
      )}
              </div>
              <form className="m-6  ">
              <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
             required
            // accept="audio/mp3"
            />
            
                <div  onClick={handleSVGClick} className="w-full border-2 h-[90px] border-gray-300 border-dashed rounded-md font-poppins custom-placeholder flex justify-center items-center cursor-pointer  ">
                <div  className='mb-8'>
                <FaUpload/>
                </div>
                  {/* <div className="absolute text-neutral-500 ">Upload Here</div> */}
                  <div className=" absolute text-neutral-500 text-center ">Upload Here</div>
                 
                   {error && (
    <div className=" mb-4 absolute text-red-500  text-center mt-16">
      {error}
    </div>
  )}
   

                </div>
                
                <div onClick={ ()=> !isUploading && handleUpload()} className="  flex justify-center gap-3 items-center mt-6 bg-red-600 font-bold cursor-pointer text-[15px] font-poppins  text-white w-full h-12 rounded-md">
                  <span
                    className=""
                  >
                    Upload
                  </span>
                 
                  
                  {isUploading ? 
                                        (
                                          <div role="status">
                                              <svg aria-hidden="true" className="w-6 h-6 text-gray-200 animate-spin px-1 dark:text-gray-600 fill-black flex items-center justify-center " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                              </svg>
                                          </div>
                                        ):""}
                </div>
            
              </form>
            </div>
          </div>
        </div>
      )
    }
    </div> 
  )
}

export default BudgetRequest
